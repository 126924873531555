<template>
    <div class="container">
        <div class="page-container">
            <el-table ref="table" :data="tableData" border stripe :row-key="'messageId'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="messageId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="type" label="标签类型" align="center">
                    <template slot-scope="scope">
                        {{scope.row.type | getStateName(tagTypeList)}}
                    </template>
                </el-table-column>
                <el-table-column prop="group_name" label="标签分组名称" align="center"></el-table-column>
                <el-table-column prop="tag_name" label="标签名称" align="center"></el-table-column>
            </el-table>
            <!-- <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination> -->
        </div>
        <!-- 查看详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import userManager from "@/request/userManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
export default {
    mixins: [getList],
    props: ["userId"],
    components: {
        ShowContent,
    },
    data() {
        return {
            // 标签类型, 1-企业设置，2-用户自定义，3-规则组标签
            tagTypeList: [
                { value: 1, label: "企业设置", }, 
                { value: 2, label: "用户自定义", },
                { value: 3, label: "规则组标签", },
            ],
            dialogSeeContent: false,
            rowInfo: {
                title: '',
                content: '',
            },

        };
    },
    created() {
        this.getUserMessage();
    },
    methods: {
        async getUserMessage() {
            this.showLoading = true;
            try {
                let data = await userManager.getUserMessage({ userId: this.userId });
                console.log('UserMessage', data);
                if (data.code == 200) {
                    var toalData = JSON.parse(data.message)
                    this.tableData = [];
                    if (toalData.follow_user.length) {
                        toalData.follow_user.forEach(element => {
                            if (element.tags.length) {
                                element.tags.forEach(item => {
                                    this.tableData.push(item);
                                });
                            }
                        });
                    }



                }
                this.showLoading = false;
            } catch (error) {
                console.log("error", error);
                this.showLoading = false;
            }
        },
        rowContent(row) {
            this.rowInfo.title = "备注";
            this.rowInfo.content = row.changeMemo;
            this.dialogSeeContent = true;
        },
    },
};
</script>

<style scoped></style>
