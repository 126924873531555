<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s">
        <el-form-item>
          <el-input type="text" placeholder="请输入圈子名称" v-model="query.circleName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="请输入用户名称" v-model="query.nickName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.auditState" placeholder="请选择审核状态" clearable>
            <el-option v-for="item in searchForm.auditStateList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="permission.selectCircleClassInfo">
          <el-select size="small" v-model="query.circleClassId" placeholder="请选择圈子类别" clearable>
            <el-option v-for="item in searchForm.circleClassInfoList" :key="item.circleClassId" :label="item.circleClassName" :value="item.circleClassId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="refreshData">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="onAdd" v-if="permission.saveCircleInfo">添加</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="table" :data="tableData" border stripe :row-key="'circleId'" v-loading="showLoading" :height="tableHeight">
        <el-table-column prop="circleId" label="编号" align="center" width="50">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="circleName" label="圈子名称" min-width="120px"></el-table-column>
        <el-table-column prop="nickName" label="发布人" min-width="100px"></el-table-column>
        <el-table-column label="LOGO" width="70" align="center">
          <template slot-scope="scope">
            <el-image class="table-td-thumb"  :src="scope.row.circleLogo" :preview-src-list="[scope.row.circleLogo]" >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="circleClassName" label="圈子分类" align="center" width="75"></el-table-column>
        <el-table-column prop="goodsClassName" label="圈子介绍" width="75" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 1)">
              <el-link type="primary">详情</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="newsCount" label="动态数" width="75" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 2)">
              <el-link type="primary">{{scope.row.newsCount}}</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="peopleCount" label="圈子人数" width="75" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 3)">
              <el-link type="primary">{{scope.row.peopleCount}}</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="isPopular" label="是否热门" align="center" width="75">
          <template slot-scope="scope">
            <i :class="[scope.row.isPopular == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
         <el-table-column prop="isTop" label="是否置顶" align="center" width="75">
          <template slot-scope="scope">
            <i :class="[scope.row.isTop == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="auditState" label="审核状态" align="center" width="85">
          <template slot-scope="scope">
            {{scope.row.auditState | getStateName(searchForm.auditStateList)}}
          </template>
        </el-table-column>
        <el-table-column prop="peopleCount" label="未通过原因" width="85" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 4)" v-if="scope.row.auditState == 3">
              <el-link type="primary">查看</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="auditTime" label="审核时间" align="center" width="140">
          <template slot-scope="scope">
            {{scope.row.auditTime | formatDate}}
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="发布时间" align="center" width="140">
          <template slot-scope="scope">
            {{scope.row.addTime | formatDate}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140px" align="center" fixed="right">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="审核" placement="top" v-if="permission.auditCircleInfo && scope.row.auditState == 1">
              <el-button class="success" type="text" icon="el-icon-s-check" @click="clickAuditInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="permission.updateCircleInfo">
              <el-button class="success" type="text" icon="el-icon-edit-outline" @click="clickEditInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="permission.removeCircleInfo">
              <el-popconfirm title="您确定要删除么？" icon-color="red" @confirm="deleteAdmin(scope.row, scope.$index)">
                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                </el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="scope.row.isPopular?'取消热门':'设置热门'" placement="top">
              <el-popconfirm :title="scope.row.isPopular?'您确定要取消热门吗？':'您确定要设置热门吗？'" icon-color="red" @confirm="editIsPopularHandle(scope.row, scope.$index)" v-if="permission.isPopular && scope.row.auditState  == 2">
                <el-button slot="reference" icon="el-icon-bottom" type="text" class="danger" v-if="scope.row.isPopular">
                </el-button>
                <el-button slot="reference" icon="el-icon-top" type="text" class="success" v-else>
                </el-button>
              </el-popconfirm>
            </el-tooltip>
             <el-tooltip class="item" effect="dark" :content="scope.row.isTop?'取消置顶':'设置置顶'" placement="top">
              <el-popconfirm :title="scope.row.isTop?'您确定要取消置顶吗？':'您确定要设置置顶吗？'" icon-color="red" @confirm="editIsTopHandle(scope.row, scope.$index)" v-if="permission.isPopular && scope.row.auditState  == 2">
                <el-button slot="reference" icon="el-icon-download" type="text" class="danger" v-if="scope.row.isTop">
                </el-button>
                <el-button slot="reference" icon="el-icon-upload2" type="text" class="success" v-else>
                </el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="选择" placement="top" v-if="permission.selectCircleList">
                <el-button class="success" type="text" icon="el-icon-circle-check"
                    @click="selectCircleHandle(scope.row)"></el-button>
            </el-tooltip>  
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <!-- 查看圈子详情 -->
    <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

    <!-- 添加圈子 -->
    <el-dialog :title="`${editForm.circleId>0 ? '编辑' : '添加'}圈子`" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form label-width="120px" ref="editForm" :model="editForm" :rules="addRules">
        <el-form-item label="圈子名称" prop="circleName">
          <el-input size="medium" v-model="editForm.circleName" placeholder="请输入圈子名称"></el-input>
        </el-form-item>
        <el-form-item label="圈子主图" prop="circleLogo">
          <UploadImageNewOne :limit="1" :imgList.sync="editForm.circleLogo" accept="image/*"></UploadImageNewOne>
          <span style="color:red">最优比例1:1</span>
        </el-form-item>
        <el-form-item label="圈子类别" prop="circleClassId">
          <el-select size="small" v-model="editForm.circleClassId" placeholder="请选择圈子类别" clearable>
            <el-option v-for="item in searchForm.circleClassInfoList" :key="item.circleClassId" :label="item.circleClassName" :value="item.circleClassId">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否热门" prop="isPopular">
          <el-checkbox v-model="editForm.isPopular" :true-label="1" :false-label="0"></el-checkbox>
        </el-form-item>
        <el-form-item label="是否置顶" prop="isTop">
          <el-checkbox v-model="editForm.isTop" :true-label="1" :false-label="0"></el-checkbox>
        </el-form-item>
        <el-form-item label="圈子简介" prop="circleBrief">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="editForm.circleBrief">
          </el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAddOrEditCancel">取 消</el-button>
        <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
      </div>
    </el-dialog>
    <!-- 审核 -->
    <el-dialog title="审核" :visible.sync="dialogAuditVisible" :modal="false" :destroy-on-close="true" fullscreen @close="resetAuditData">
      <el-form ref="auditModel" label-width="120px" :model="auditModel" :rules="auditRules">
        <el-form-item label="审核状态">
          <el-select size="small" v-model="auditModel.auditState" @change="changeStatesHandle">
            <el-option v-for="item in auditStateList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="未通过原因" prop="noPassReason" v-if="auditModel.auditState == 3" class="is-required">
          <el-input size="medium" type="textarea" style="width: 500px" maxlength="255" v-model="auditModel.noPassReason" prefix-icon="el-icon-user" placeholder="请填写未通过原因"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAuditCancel">取 消</el-button>
        <el-button type="primary" :loading="auditBtnLoading" @click="clickAuditConfirm">确定</el-button>
      </div>
    </el-dialog>
    <!-- 圈子人员列表 -->
    <el-dialog title="圈子人员列表" :visible.sync="dialogLookUserVisible" :modal="false" :destroy-on-close="true" fullscreen>
      <UserList :circleId="lookUserModel.circleId" v-if="dialogLookUserVisible" />
    </el-dialog>
    <!-- 圈子动态列表 -->
    <el-dialog title="圈子动态列表" :visible.sync="dialogLookDynamicVisible" :modal="false" :destroy-on-close="true" fullscreen>
      <DynamicList :circleId="lookUserModel.circleId" v-if="dialogLookDynamicVisible" />
    </el-dialog>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import circleManager from '@/request/circleManager.js'
import ShowContent from '@/components/common/subgroup/ShowContent.vue'
import UploadImageNewOne from '@/components/common/subgroup/UploadImageNewOne.vue' //上传图片
// import UserList from '../../user/UserList.vue'
import DynamicList from '../dynamic/DynamicList.vue'
export default {
  mixins: [getList],
  components: {
    ShowContent,
    UploadImageNewOne,
    DynamicList,
    UserList: () => import('../../user/UserList.vue'),
  },
  props: ['userId'],
  data() {
    return {
      requst: {
        module: circleManager,
        url: 'circleInfoList',
        addUrl: 'saveCircleInfo',
        updateUrl: 'updateCircleInfo',
      },
      dialogSeeContent: false,
      rowInfo: {},
      query: {
        circleName: '', //搜索词
        nickName: '',
        auditState: '',
        circleClassId: '',
        userId: this.userId,
      },
      searchForm: {
        auditStateList: [
          {
            value: 1,
            label: '待审核',
          },
          {
            value: 2,
            label: '审核通过',
          },
          {
            value: 3,
            label: '审核未通过',
          },
        ],
        circleClassInfoList: [],
      },
      //添加和修改
      addOrEditDialog: false,
      addOrEditBtnLoading: false,
      editForm: {
        circleId: '',
        circleLogo: '',
        circleName: '',
        circleBrief: '',
        circleClassId: '',
        isPopular: 1,
        isTop:0,
      },
      addRules: {
        circleName: [
          { required: true, message: '请输入圈子名称', trigger: 'blur' },
        ],
        circleClassId: [
          { required: true, message: '请选择圈子类别', trigger: 'blur' },
        ],
        circleLogo: [
          { required: true, message: '请上传LOGO', trigger: 'blur' },
        ],
        circleBrief: [
          { required: true, message: '请输入圈子简介', trigger: 'blur' },
        ],
      },
      //审核
      dialogAuditVisible: false,
      auditBtnLoading: false,
      auditModel: {
        circleId: 0,
        auditState: 2,
        noPassReason: '',
      },
      auditRules: {
        noPassReason: [
          { required: true, message: '请输入拒绝原因', trigger: 'blur' },
        ],
      },
      auditStateList: [
        {
          value: 2,
          label: '审核通过',
        },
        {
          value: 3,
          label: '审核未通过',
        },
      ],

      //圈子人员
      dialogLookUserVisible: false,
      dialogLookDynamicVisible: false,
      lookUserModel: {
        circleId: 0,
      },
    }
  },
  created() {
    this.handlePermission()
    if (this.permission.selectCircleClassInfo) {
      this.getCircleClassInfoList()
    }
  },
  methods: {
    async getCircleClassInfoList() {
      try {
        let data = await circleManager.circleClassInfoList()
        if (data.code == 200) {
          this.searchForm.circleClassInfoList = data.data
        }
      } catch (error) {
        //
      }
    },

    showContenHandle(row, type) {
      switch (type) {
        case 1:
          this.rowInfo.title = '圈子介绍'
          this.rowInfo.content = row.circleBrief
          this.dialogSeeContent = true
          break
        case 2:
          if (this.permission.lookNewsList) {
            this.lookUserModel.circleId = row.circleId
            this.dialogLookDynamicVisible = true
          } else {
            this.$message.error('您没有权限查看')
          }
          break
        case 3:
          if (this.permission.lookUserList) {
            this.lookUserModel.circleId = row.circleId
            this.dialogLookUserVisible = true
          } else {
            this.$message.error('您没有权限查看')
          }
          break
        case 4:
          this.rowInfo.title = '未通过原因'
          this.rowInfo.content = row.noPassReason
          this.dialogSeeContent = true
          break

        default:
          break
      }
    },

    // 点击添加
    onAdd() {
      this.addOrEditDialog = true
    },

    // 点击添加---取消
    clickAddOrEditCancel() {
      this.resetAddOrEditData()
    },
    // 点击添加---确定
    clickAddOrEditConfirm() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            this.addOrEditBtnLoading = true
            let dataValue = JSON.parse(JSON.stringify(this.editForm))
            let data = await this.requst.module[
              this.editForm.circleId > 0
                ? this.requst.updateUrl
                : this.requst.addUrl
            ](dataValue)
            if (data.code == 200) {
              this.$message({
                message: data.message,
                type: 'success',
              })
              this.refresCurrentPagehData()
              this.resetAddOrEditData()
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.addOrEditBtnLoading = false
          }
        }
      })
    },

    // 清除添加数据
    resetAddOrEditData() {
      this.addOrEditDialog = false
      this.editForm = {
        circleId: '',
        circleLogo: '',
        circleName: '',
        circleBrief: '',
        circleClassId: '',
        isPopular: 1,
        isTop:0
      }
    },
    // 点击编辑信息
    async clickEditInfo(row) {
      this.editForm = {
        circleId: row.circleId,
        circleLogo: row.circleLogo,
        circleName: row.circleName,
        circleBrief: row.circleBrief,
        circleClassId: row.circleClassId,
        isPopular: row.isPopular,
        isTop:row.isTop
      }
      this.addOrEditDialog = true
    },

    // 点击删除
    async deleteAdmin(row, index) {
      try {
        let { code, message } = await circleManager.removeCircleInfo({
          circleId: row.circleId,
        })
        if (code === 200) {
          this.$message({
            message: message,
            type: 'success',
          })
          this.delRefresh(index)
        }
      } catch (error) {
        console.log(error)
      }
    },

    //审核
    clickAuditInfo(row) {
      this.auditModel.circleId = row.circleId
      this.dialogAuditVisible = true
    },
    resetAuditData() {
      this.dialogAuditVisible = false
      this.auditModel = {
        circleId: 0,
        auditState: 2,
        noPassReason: '',
      }
    },
    clickAuditCancel() {
      this.resetAuditData()
    },
    async clickAuditConfirm() {
      this.$refs.auditModel.validate(async (valid) => {
        if (valid) {
          this.auditBtnLoading = true
          try {
            let data = await circleManager.auditCircleInfo(this.auditModel)
            if (data.code == 200) {
              this.$message.success(data.message)
              this.dialogAuditVisible = false
              this.refresCurrentPagehData()
              this.resetAuditData()
            }
            this.auditBtnLoading = false
          } catch (error) {
            this.auditBtnLoading = false
          }
        } else {
          return false
        }
      })
    },
    changeStatesHandle() {
      this.auditModel.noPassReason = ''
    },
    rejectedContent(row) {
      this.rowInfo.title = '被拒原因'
      this.rowInfo.content = row.noPassReason
      this.dialogSeeContent = true
    },

    //修改热门状态
    async editIsPopularHandle(row) {
      try {
        let data = await circleManager.isPopularCircleInfo({
          circleId: row.circleId,
          isPopular: row.isPopular ? 0 : 1,
        })
        if (data.code == 200) {
          this.$message.success(data.message)
          this.refresCurrentPagehData()
        }
      } catch (error) {
        //
      }
    },
    async editIsTopHandle(row){
      try {
        let data = await circleManager.isTopCircleInfo({
          circleId: row.circleId,
          isTop: row.isTop ? 0 : 1,
        })
        if (data.code == 200) {
          this.$message.success(data.message)
          this.refresCurrentPagehData()
        }
      } catch (error) {
        //
      }
    },

    selectCircleHandle(row){
      this.$emit("selectCircle",row);
    }
  },
}
</script>

<style scoped>

</style>
