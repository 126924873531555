var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s warp", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入用户名" },
                    model: {
                      value: _vm.query.nickName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "nickName", $$v)
                      },
                      expression: "query.nickName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入手机号" },
                    model: {
                      value: _vm.query.loginName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "loginName", $$v)
                      },
                      expression: "query.loginName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入门店名" },
                    model: {
                      value: _vm.query.storeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "storeName", $$v)
                      },
                      expression: "query.storeName",
                    },
                  }),
                ],
                1
              ),
              _vm.circleId == null
                ? _c(
                    "el-form-item",
                    { staticStyle: { width: "100px" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "small",
                            placeholder: "用户类型",
                            clearable: "",
                          },
                          on: { change: _vm.changeUserTypeHandle },
                          model: {
                            value: _vm.query.userType,
                            callback: function ($$v) {
                              _vm.$set(_vm.query, "userType", $$v)
                            },
                            expression: "query.userType",
                          },
                        },
                        _vm._l(_vm.userTypeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { staticStyle: { width: "100px" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "在线状态",
                        clearable: "",
                      },
                      model: {
                        value: _vm.query.onlineState,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "onlineState", $$v)
                        },
                        expression: "query.onlineState",
                      },
                    },
                    _vm._l(_vm.onlineStateList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { width: "100px" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "是否推荐",
                        clearable: "",
                      },
                      model: {
                        value: _vm.query.isRecommend,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isRecommend", $$v)
                        },
                        expression: "query.isRecommend",
                      },
                    },
                    _vm._l(_vm.isRecommendList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { width: "100px" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "是否禁用",
                        clearable: "",
                      },
                      model: {
                        value: _vm.query.isUse,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isUse", $$v)
                        },
                        expression: "query.isUse",
                      },
                    },
                    _vm._l(_vm.isUseList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { width: "100px" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "是否官方",
                        clearable: "",
                        disabled: _vm.isOfficial == 1,
                      },
                      model: {
                        value: _vm.query.isOfficial,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isOfficial", $$v)
                        },
                        expression: "query.isOfficial",
                      },
                    },
                    _vm._l(_vm.isOfficialList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { width: "100px" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "是否注销",
                        clearable: "",
                      },
                      model: {
                        value: _vm.query.isLogout,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isLogout", $$v)
                        },
                        expression: "query.isLogout",
                      },
                    },
                    _vm._l(_vm.isLogoutList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshData },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _vm.permission.addUser
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("添加")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.permission.updateLabel
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.updateLabelHandle },
                        },
                        [_vm._v("同步企业微信标签")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "userId",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "userId",
                  label: "编号",
                  align: "center",
                  width: "60",
                  fixed: "left",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "nickName",
                  label: "昵称",
                  "min-width": "120",
                  align: "center",
                  fixed: "left",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "用户头像", width: "90", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-image",
                          {
                            staticClass: "table-td-thumb",
                            attrs: {
                              src: scope.row.headImg,
                              "preview-src-list": [scope.row.headImg],
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "loginName",
                  label: "手机号",
                  "min-width": "100",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "sex", label: "性别", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.sex == 1
                          ? _c("span", [_vm._v("男")])
                          : scope.row.sex == 2
                          ? _c("span", [_vm._v("女")])
                          : _c("span"),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "age",
                  label: "年龄",
                  "min-width": "100",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "occupationName",
                  label: "职业",
                  "min-width": "100",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "用户标签", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.userTagHandle(scope.row)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v("查看"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "personalSignature",
                  label: "个性签名",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showContentHandle(scope.row, 1)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v("查看"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "userType", label: "用户类型", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getStateName")(
                                scope.row.userType,
                                _vm.userTypeList
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "storeName", label: "门店名", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.storeName || "--") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "subCount",
                  label: "下级数量",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.subUserHandle(scope.row)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(_vm._s(scope.row.subCount)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "integral", label: "用户积分", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.userIntegralHandle(scope.row)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(_vm._s(scope.row.integral)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "accumulatedIncome",
                  label: "累积收益",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.userAccumulatedIncomeHandle(
                                  scope.row
                                )
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(_vm._s(scope.row.accumulatedIncome)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "withdrawableIncome",
                  label: "可提现收益",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "withdrawalIncome",
                  label: "提现收益",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.userWithdrawalIncomeHandle(scope.row)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(_vm._s(scope.row.withdrawalIncome)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "circleCount",
                  label: "圈子数",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.userCircleCountHandle(scope.row)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(_vm._s(scope.row.circleCount)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "newsCount", label: "动态数", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.userNewsCountHandle(scope.row)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(_vm._s(scope.row.newsCount)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "followCount",
                  label: "关注数",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "fansCount", label: "粉丝数", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "onlineState",
                  label: "在线状态",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.userType == 3
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("getStateName")(
                                      scope.row.onlineState,
                                      _vm.onlineStateList
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("span", [_vm._v("--")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isAutoOnline",
                  label: "是否开启自动上线",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isAutoOnline == 0
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isRecommend",
                  label: "是否推荐",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isRecommend == 1
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isLogout",
                  label: "是否注销",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isLogout == 1
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isOfficial",
                  label: "是否官方",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isOfficial == 1
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isUse",
                  label: "是否可用",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isUse == 0
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "registTime",
                  label: "注册时间",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatDate")(scope.row.registTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "180px",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.permission.disableUser
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    scope.row.isUse === 0 ? "禁用" : "解禁",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要修改用户禁用状态吗？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.setEditIsUseHandle(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        icon:
                                          scope.row.isUse === 0
                                            ? "el-icon-close"
                                            : "el-icon-check",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.setOfficial
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    scope.row.isOfficial === 1
                                      ? "取消官方"
                                      : "设置官方",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要修改用户是否官方状态吗？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.setOfficialHandle(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "success",
                                      attrs: {
                                        slot: "reference",
                                        icon:
                                          scope.row.isOfficial === 1
                                            ? "el-icon-s-custom"
                                            : "el-icon-s-custom",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.setRecommend
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    scope.row.isRecommend === 1
                                      ? "取消推荐"
                                      : "设置推荐",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要修改用户是否推荐状态吗？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.setRecommendHandle(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "success",
                                      attrs: {
                                        slot: "reference",
                                        icon:
                                          scope.row.isRecommend === 1
                                            ? "el-icon-download"
                                            : "el-icon-upload2",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.setUserType
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "设置用户身份",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-user-solid",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setUserTypeHandle(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.changeIntegral
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "修改用户积分",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: { type: "text", icon: "el-icon-coin" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeIntegralHandle(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.selectUserList
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "选择",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-circle-check",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectUserHandle(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.offset,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: `用户积分明细`,
            visible: _vm.dialogUserIntegralListVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogUserIntegralListVisible = $event
            },
          },
        },
        [
          _vm.dialogUserIntegralListVisible
            ? _c("IntegralChangeList", { attrs: { userId: _vm.selectUserId } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: `下级用户列表`,
            visible: _vm.dialogSubDirectListVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSubDirectListVisible = $event
            },
          },
        },
        [
          _vm.dialogSubDirectListVisible
            ? _c("name-list", { attrs: { pUserId: _vm.selectUserId } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `设置用户身份`,
            visible: _vm.dialogUserTypeFormVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogUserTypeFormVisible = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "userTypeForm",
              attrs: {
                "label-width": "150px",
                model: _vm.userTypeForm,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户身份", prop: "userType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { size: "small" },
                      on: { change: _vm.userTypeChange },
                      model: {
                        value: _vm.userTypeForm.userType,
                        callback: function ($$v) {
                          _vm.$set(_vm.userTypeForm, "userType", $$v)
                        },
                        expression: "userTypeForm.userType",
                      },
                    },
                    _vm._l(_vm.userTypeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.userTypeForm.userType == 2 || _vm.userTypeForm.userType == 4
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "is-required",
                      attrs: { label: "门店ID", prop: "storeId" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: { placeholder: "请选择门店", disabled: "" },
                        model: {
                          value: _vm.userTypeForm.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.userTypeForm, "storeId", $$v)
                          },
                          expression: "userTypeForm.storeId",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.createIdChangeNew },
                        },
                        [_vm._v("选择")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickUserTypeConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "选择门店",
            visible: _vm.dialogStoreFormVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogStoreFormVisible = $event
            },
          },
        },
        [
          _vm.dialogStoreFormVisible
            ? _c("StoreList", {
                key: "key" + _vm.currentKey,
                on: { changeSelectStore: _vm.handleChangeSelectStore },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户动态列表",
            visible: _vm.dialogLookDynamicVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLookDynamicVisible = $event
            },
          },
        },
        [
          _vm.dialogLookDynamicVisible
            ? _c("DynamicList", { attrs: { userId: _vm.selectUserId } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户圈子列表",
            visible: _vm.dialogLookCircleVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLookCircleVisible = $event
            },
          },
        },
        [
          _vm.dialogLookCircleVisible
            ? _c("CircleList", { attrs: { userId: _vm.selectUserId } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改用户积分",
            visible: _vm.dialogEditUserIntegralFormVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditUserIntegralFormVisible = $event
            },
            close: _vm.resetEditIntegralData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editUserIntegralModel",
              attrs: {
                "label-width": "120px",
                model: _vm.editUserIntegralModel,
                rules: _vm.editIntegralRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "当前积分:", prop: "userFees" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "medium",
                      "prefix-icon": "el-icon-coin",
                      placeholder: "",
                      disabled: true,
                    },
                    model: {
                      value: _vm.editUserIntegralModel.userIntegrals,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editUserIntegralModel,
                          "userIntegrals",
                          $$v
                        )
                      },
                      expression: "editUserIntegralModel.userIntegrals",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.editUserIntegralModel.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.editUserIntegralModel, "type", $$v)
                        },
                        expression: "editUserIntegralModel.type",
                      },
                    },
                    _vm._l(_vm.editIntegralTypeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "变动积分:", prop: "integral" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "medium",
                      type: "number",
                      "prefix-icon": "el-icon-coin",
                      placeholder: "请输入变动积分",
                    },
                    model: {
                      value: _vm.editUserIntegralModel.integral,
                      callback: function ($$v) {
                        _vm.$set(_vm.editUserIntegralModel, "integral", $$v)
                      },
                      expression: "editUserIntegralModel.integral",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickEditIntegralCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.editUserIntegralBtnLoading,
                  },
                  on: { click: _vm.editUserIntegralConfirm },
                },
                [_vm._v("确定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${"添加"}用户`,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "label-width": "120px",
                model: _vm.editForm,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户昵称", prop: "nickName" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入用户昵称" },
                    model: {
                      value: _vm.editForm.nickName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "nickName", $$v)
                      },
                      expression: "editForm.nickName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "用户头像", prop: "headImg" },
                },
                [
                  _c("UploadImageNewOne", {
                    attrs: {
                      limit: 1,
                      imgList: _vm.editForm.headImg,
                      accept: "image/*",
                    },
                    on: {
                      "update:imgList": function ($event) {
                        return _vm.$set(_vm.editForm, "headImg", $event)
                      },
                      "update:img-list": function ($event) {
                        return _vm.$set(_vm.editForm, "headImg", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickAddUserConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "余额变动记录",
            visible: _vm.dialogBalanceChangeListVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogBalanceChangeListVisible = $event
            },
          },
        },
        [
          _vm.dialogBalanceChangeListVisible
            ? _c("BalanceChangeList", { attrs: { userId: _vm.selectUserId } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提现记录",
            visible: _vm.dialogWithdrawalListVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogWithdrawalListVisible = $event
            },
          },
        },
        [
          _vm.dialogWithdrawalListVisible
            ? _c("WithdrawalList", { attrs: { userId: _vm.selectUserId } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户标签",
            visible: _vm.dialogUserTagListVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogUserTagListVisible = $event
            },
          },
        },
        [
          _vm.dialogUserTagListVisible
            ? _c("UserTagList", { attrs: { userId: _vm.selectUserId } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }