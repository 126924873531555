var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "page-container" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.showLoading,
                expression: "showLoading",
              },
            ],
            ref: "table",
            attrs: {
              data: _vm.tableData,
              border: "",
              stripe: "",
              "row-key": "topicId",
              height: _vm.tableHeight,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "topicId",
                label: "编号",
                align: "center",
                width: "60",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_vm._v(" " + _vm._s(scope.$index + 1))]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "topicName", label: "话题名称", align: "left" },
            }),
          ],
          1
        ),
        _c("el-pagination", {
          ref: "pagination",
          attrs: {
            "current-page": _vm.query.offset,
            "page-sizes": [10, 30, 50, 100],
            "page-size": _vm.query.limit,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.pageTotal,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }