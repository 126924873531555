<template>
  <div class="container">
    <div class="page-container">
      <el-table ref="table" :data="tableData" border stripe :row-key="'topicId'" v-loading="showLoading" :height="tableHeight">
        <el-table-column prop="topicId" label="编号" align="center" width="60">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="topicName" label="话题名称" align="left"></el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import circleManager from '@/request/circleManager.js'
export default {
  mixins: [getList],
  props:["newsId"],
  data() {
    return {
      requst: {
        module: circleManager,
        url: 'newsTopicList',
        addUrl: '',
        updateUrl: '',
      },
      query: {
        newsId: this.newsId, //搜索词
      },
      
    }
  },

}
</script>

<style scoped>
</style>
