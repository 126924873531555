var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入动态标题" },
                    model: {
                      value: _vm.query.newsTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "newsTitle", $$v)
                      },
                      expression: "query.newsTitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入圈子名称" },
                    model: {
                      value: _vm.query.circleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "circleName", $$v)
                      },
                      expression: "query.circleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入用户名称" },
                    model: {
                      value: _vm.query.nickName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "nickName", $$v)
                      },
                      expression: "query.nickName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "请选择是否置顶",
                        clearable: "",
                      },
                      model: {
                        value: _vm.query.isTop,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isTop", $$v)
                        },
                        expression: "query.isTop",
                      },
                    },
                    _vm._l(_vm.searchForm.isTopList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "请选择是否官方",
                        clearable: "",
                      },
                      model: {
                        value: _vm.query.isOfficial,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isOfficial", $$v)
                        },
                        expression: "query.isOfficial",
                      },
                    },
                    _vm._l(_vm.searchForm.isOfficialList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "请选择动态类型",
                        clearable: "",
                      },
                      model: {
                        value: _vm.query.newsType,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "newsType", $$v)
                        },
                        expression: "query.newsType",
                      },
                    },
                    _vm._l(_vm.searchForm.newsTypeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshData },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.permission.saveNewsInfo
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "newsId",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-descriptions",
                          {
                            staticClass: "margin-top",
                            attrs: { title: "动态地址", column: 3, border: "" },
                          },
                          [
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v(" 经度 "),
                                ]),
                                _vm._v(" " + _vm._s(props.row.longitude) + " "),
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v(" 纬度 "),
                                ]),
                                _vm._v(" " + _vm._s(props.row.latitude) + " "),
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v(" 定位地址 "),
                                ]),
                                _vm._v(
                                  " " + _vm._s(props.row.locationAddress) + " "
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "newsId",
                  label: "编号",
                  align: "center",
                  width: "50",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "newsTitle",
                  label: "动态标题",
                  align: "center",
                  "min-width": "140px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.newsTitle.length > 9
                                ? scope.row.newsTitle.substr(0, 3) +
                                    "..." +
                                    scope.row.newsTitle.substr(
                                      scope.row.newsTitle.length - 3,
                                      scope.row.newsTitle.length - 1
                                    )
                                : scope.row.newsTitle
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "circleName",
                  label: "圈子名称",
                  align: "center",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "nickName",
                  label: "发布人名称",
                  align: "center",
                  "min-width": "110px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "newsType",
                  label: "动态类型",
                  align: "center",
                  width: "75",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getStateName")(
                                scope.row.newsType,
                                _vm.searchForm.newsTypeList
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsClassName",
                  label: "动态内容",
                  width: "75",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showContenHandle(scope.row, 1)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v("查看详情"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsClassName",
                  label: "图片/视频",
                  width: "75",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.newsType == 1
                          ? _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.showContenHandle(scope.row, 3)
                                  },
                                },
                              },
                              [
                                _c("el-link", { attrs: { type: "primary" } }, [
                                  _vm._v("查看图集"),
                                ]),
                              ],
                              1
                            )
                          : scope.row.newsType == 2
                          ? _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.showContenHandle(scope.row, 5)
                                  },
                                },
                              },
                              [
                                _c("el-link", { attrs: { type: "primary" } }, [
                                  _vm._v("查看视频"),
                                ]),
                              ],
                              1
                            )
                          : _c("span"),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsClassName",
                  label: "动态话题",
                  width: "75",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showContenHandle(scope.row, 2)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v("详情"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "动态评论", width: "70", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showContenHandle(scope.row, 4)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v("详情"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isTop",
                  label: "是否置顶",
                  align: "center",
                  width: "70px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isTop == 1
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isOfficial",
                  label: "是否官方",
                  align: "center",
                  width: "70px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isOfficial == 1
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "likeNumber",
                  label: "点赞数量",
                  align: "center",
                  width: "70px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "playNumber",
                  label: "播放量",
                  align: "center",
                  width: "70px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "addTime",
                  label: "发布时间",
                  align: "center",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatDate")(scope.row.addTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "110px",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.permission.updateNewsInfo
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "编辑",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-edit-outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickEditInfo(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.removeNewsInfo
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "删除",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要删除么？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.deleteAdmin(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        icon: "el-icon-delete",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: scope.row.isTop
                                ? "取消置顶"
                                : "设置置顶",
                              placement: "top",
                            },
                          },
                          [
                            _vm.permission.isTopNewsInfo
                              ? _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: scope.row.isTop
                                        ? "您确定要取消置顶吗？"
                                        : "您确定要设置置顶吗？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.editIsTopHandle(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    scope.row.isTop
                                      ? _c("el-button", {
                                          staticClass: "danger",
                                          attrs: {
                                            slot: "reference",
                                            icon: "el-icon-download",
                                            type: "text",
                                          },
                                          slot: "reference",
                                        })
                                      : _c("el-button", {
                                          staticClass: "success",
                                          attrs: {
                                            slot: "reference",
                                            icon: "el-icon-upload2",
                                            type: "text",
                                          },
                                          slot: "reference",
                                        }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.offset,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${_vm.editForm.newsId ? "编辑" : "添加"}动态`,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "label-width": "120px",
                model: _vm.editForm,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "动态标题",
                    prop: _vm.editForm.newsType == 3 ? "newsTitle" : "",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入动态标题" },
                    model: {
                      value: _vm.editForm.newsTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "newsTitle", $$v)
                      },
                      expression: "editForm.newsTitle",
                    },
                  }),
                ],
                1
              ),
              _vm.editForm.newsId == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "动态类型", prop: "newsType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "small",
                            placeholder: "请选择动态类型",
                          },
                          on: { change: _vm.changeNewsTypeHandle },
                          model: {
                            value: _vm.editForm.newsType,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "newsType", $$v)
                            },
                            expression: "editForm.newsType",
                          },
                        },
                        _vm._l(_vm.searchForm.newsTypeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "选择圈子", prop: "circleId" } },
                [
                  _c("el-button", { on: { click: _vm.selectCircleHandle } }, [
                    _vm._v("选择圈子"),
                  ]),
                  _c("el-input", {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { placeholder: "请选择圈子", disabled: "" },
                    model: {
                      value: _vm.editForm.circleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "circleName", $$v)
                      },
                      expression: "editForm.circleName",
                    },
                  }),
                ],
                1
              ),
              _vm.editForm.newsId == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择发布用户", prop: "userId" } },
                    [
                      _c("el-button", { on: { click: _vm.selectUserHandle } }, [
                        _vm._v("选择用户"),
                      ]),
                      _c("el-input", {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { placeholder: "请选择用户", disabled: "" },
                        model: {
                          value: _vm.editForm.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "userName", $$v)
                          },
                          expression: "editForm.userName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.newsId == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择话题", prop: "newsTopicInfoJson" } },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.selectTopicHandle } },
                        [_vm._v("选择话题")]
                      ),
                      _c("el-button", { on: { click: _vm.clearTopicHandle } }, [
                        _vm._v("重置"),
                      ]),
                      _c("el-input", {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { placeholder: "请选择话题", disabled: "" },
                        model: {
                          value: _vm.editForm.newsTopicName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "newsTopicName", $$v)
                          },
                          expression: "editForm.newsTopicName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.newsType == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "图文内容", prop: "newsContent" } },
                    [
                      _vm.addOrEditDialog && _vm.editForm.newsType == 3
                        ? _c("Editor", {
                            model: {
                              value: _vm.editForm.newsContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "newsContent", $$v)
                              },
                              expression: "editForm.newsContent",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.newsType == 1 || _vm.editForm.newsType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "内容", prop: "newsContent" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "请输入内容" },
                        model: {
                          value: _vm.editForm.newsContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "newsContent", $$v)
                          },
                          expression: "editForm.newsContent",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.newsId == 0 && _vm.editForm.newsType == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "图片", prop: "newsGalleryInfoJson" } },
                    [
                      _c("UploadImageNewOne", {
                        attrs: {
                          imgList: _vm.editForm.galleryList,
                          accept: "image/*",
                        },
                        on: {
                          "update:imgList": function ($event) {
                            return _vm.$set(_vm.editForm, "galleryList", $event)
                          },
                          "update:img-list": function ($event) {
                            return _vm.$set(_vm.editForm, "galleryList", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.newsType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "视频封面", prop: "videoImp" } },
                    [
                      _c("UploadImageNewOne", {
                        attrs: {
                          imgList: _vm.editForm.videoImp,
                          limit: 1,
                          accept: "image/*",
                        },
                        on: {
                          "update:imgList": function ($event) {
                            return _vm.$set(_vm.editForm, "videoImp", $event)
                          },
                          "update:img-list": function ($event) {
                            return _vm.$set(_vm.editForm, "videoImp", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.newsType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "视频地址", prop: "videoAddress" } },
                    [
                      _c("UploadFileNewOne", {
                        attrs: {
                          fileList: _vm.editForm.videoAddress,
                          limit: 1,
                          accept: "video/*",
                        },
                        on: {
                          "update:fileList": function ($event) {
                            return _vm.$set(
                              _vm.editForm,
                              "videoAddress",
                              $event
                            )
                          },
                          "update:file-list": function ($event) {
                            return _vm.$set(
                              _vm.editForm,
                              "videoAddress",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "位置信息", prop: "locationAddress" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "fetch-suggestions": _vm.querySearchAsync,
                      placeholder: "请填写详细地址",
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.editForm.locationAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "locationAddress", $$v)
                      },
                      expression: "editForm.locationAddress",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "地图" } }, [
                _c(
                  "div",
                  { staticClass: "mapContainer" },
                  [
                    _vm.addOrEditDialog
                      ? _c("MapView", {
                          attrs: {
                            latitude: this.editForm.latitude,
                            longitude: this.editForm.longitude,
                          },
                          on: {
                            getAMap: _vm.getAMapHandle,
                            clickMapHandle: _vm.clickMapHandle,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "经度", prop: "longitude" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "经度",
                      disabled: "",
                    },
                    model: {
                      value: _vm.editForm.longitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "longitude", $$v)
                      },
                      expression: "editForm.longitude",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "纬度", prop: "latitude" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "纬度",
                      disabled: "",
                    },
                    model: {
                      value: _vm.editForm.latitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "latitude", $$v)
                      },
                      expression: "editForm.latitude",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否置顶", prop: "isTop" } },
                [
                  _c("el-checkbox", {
                    attrs: { "true-label": 1, "false-label": 0 },
                    model: {
                      value: _vm.editForm.isTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "isTop", $$v)
                      },
                      expression: "editForm.isTop",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickAddOrEditConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "动态话题",
            visible: _vm.dialogLookNewsTopicVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLookNewsTopicVisible = $event
            },
          },
        },
        [
          _vm.dialogLookNewsTopicVisible
            ? _c("DynamicTopicList", {
                attrs: { newsId: _vm.lookModel.newsId },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "动态评论 ",
            visible: _vm.dialogLookNewsCommnentVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLookNewsCommnentVisible = $event
            },
          },
        },
        [
          _vm.dialogLookNewsCommnentVisible
            ? _c("DynamicCommenList", {
                attrs: { newsId: _vm.lookModel.newsId },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "动态图集",
            visible: _vm.dialogLookGalleryVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLookGalleryVisible = $event
            },
          },
        },
        [
          _vm.dialogLookGalleryVisible
            ? _c("DynamicGalleryList", {
                attrs: { newsId: _vm.lookModel.newsId },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "选择圈子",
            visible: _vm.dialogSelectCircleVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSelectCircleVisible = $event
            },
          },
        },
        [
          _vm.dialogSelectCircleVisible
            ? _c("CircleList", { on: { selectCircle: _vm.changeCircleHandle } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "选择用户",
            visible: _vm.dialogSelectUserVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSelectUserVisible = $event
            },
          },
        },
        [
          _vm.dialogSelectUserVisible
            ? _c("UserList", { on: { selectUser: _vm.changeUserHandle } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "选择话题",
            visible: _vm.dialogSelectTopicVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSelectTopicVisible = $event
            },
          },
        },
        [
          _vm.dialogSelectTopicVisible
            ? _c("TopicList", { on: { selectTopic: _vm.changeTopicHandle } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }