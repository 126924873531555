<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-select size="small" v-model="query.isIncome" placeholder="请选择是否收入" clearable>
                        <el-option v-for="item in isIncomeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select size="small" v-model="query.changeType" placeholder="请选择变动类型" clearable>
                        <el-option v-for="item in changeTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'messageId'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="messageId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="changeTitle" label="变动标题" align="center"></el-table-column>
                <!-- <el-table-column prop="changeDesc" label="变动详情" align="center"></el-table-column> -->
                <el-table-column prop="changeIntegral" label="变动积分" align="center"></el-table-column>
                <el-table-column prop="totalIntegral" label="积分总额" align="center"></el-table-column>
                <el-table-column prop="isIncome" label="是否收入" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isIncome == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="changeType" label="变动类型" align="center">
                    <template slot-scope="scope">
                        {{scope.row.changeType | getStateName(changeTypeList)}}
                    </template>
                </el-table-column>
                <el-table-column prop="changeMemo" label="备注" align="center">
                    <template slot-scope="scope">
                        <el-link type="primary" @click="rowContent(scope.row)">详情</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="addTime" label="添加时间" width="140" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.addTime | formatDate }}
                    </template>
                </el-table-column>

            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import userManager from "@/request/userManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
export default {
    mixins: [getList],
    props: ["userId"],
    components: {
        ShowContent,
    },
    data() {
        return {
            requst: {
                module: userManager,
                url: 'integralList',
            },
            query: {
                userId: this.userId,
                isIncome: '',
                changeType: '',
            },
            // 变动类型【1:发布帖子；2:兑换商品 3:评论帖子 4:点赞】
            changeTypeList: [
                {
                    value: 1,
                    label: "发布帖子",
                },
                {
                    value: 2,
                    label: "兑换商品",
                },
                {
                    value: 3,
                    label: "评论帖子",
                },
                {
                    value: 4,
                    label: "点赞",
                },
                {
                    value: 5,
                    label: "系统修改",
                },
            ],
            isIncomeList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
            dialogSeeContent: false,
            rowInfo: {
                title: '',
                content: '',
            },

        };
    },
    created() {

    },
    methods: {
        rowContent(row) {
            this.rowInfo.title = "备注";
            this.rowInfo.content = row.changeMemo;
            this.dialogSeeContent = true;
        },
    },
};
</script>

<style scoped>
</style>
