<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s">
        <el-form-item>
          <el-input type="text" placeholder="请输入评论内容" v-model="query.commentContent"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="请输入用户名称" v-model="query.nickName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="refreshData">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="onAdd" v-if="permission.commentNewsCommentInfo">添加</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="table" :data="tableData" border stripe v-loading="showLoading" :height="tableHeight">
        <el-table-column label="编号" align="center" width="60">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="nickName" label="用户名称" align="center" min-width="100px"></el-table-column>
        <el-table-column prop="loginName" label="用户手机号" align="center" width="100px"></el-table-column>
        <el-table-column label="查看图集" width="90px" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 1)" v-if="scope.row.fCommentId==0">
              <el-link type="primary">查看图集</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="commentContent" label="评论内容" min-width="200px"></el-table-column>
        <el-table-column label="操作" width="110px" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="permission.updateNewsCommentInfo">
              <el-button class="success" type="text" icon="el-icon-edit-outline" @click="clickEditInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="回复" placement="top" v-if="permission.replyNewsCommentInfo">
              <el-button class="success" type="text" icon="el-icon-s-comment" @click="replyEditInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="permission.removeNewsCommentInfo">
              <el-popconfirm title="您确定要删除么？" icon-color="red" @confirm="deleteAdmin(scope.row, scope.$index)">
                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                </el-button>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <!-- 添加动态 -->
    <el-dialog :title="addOrEditTitle" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form label-width="120px" ref="editForm" :model="editForm" :rules="addRules">
        <el-form-item label="选择发布用户" prop="userId" v-if="editForm.commentId==0||editForm.selectType == 2">
          <el-button @click="selectUserHandle">选择用户</el-button>
          <el-input style="margin-top:10px" placeholder="请选择用户" v-model="editForm.nickName" disabled></el-input>
        </el-form-item>
        <el-form-item label="动态评论" prop="commentContent">
          <el-input size="medium" type="textarea" v-model="editForm.commentContent" placeholder="请输入动态标题"></el-input>
        </el-form-item>
        <el-form-item label="评论图片" v-if="editForm.commentId == 0">
          <UploadImageNewOne limit="9" :imgList.sync="editForm.commentGalleryJson" accept="image/*"></UploadImageNewOne>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAddOrEditCancel">取 消</el-button>
        <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
      </div>
    </el-dialog>
    <!-- 选择用户 -->
    <el-dialog title="选择用户" :visible.sync="dialogSelectUserVisible" :modal="false" :destroy-on-close="true" fullscreen class="absolute">
      <UserList @selectUser="changeUserHandle" v-if="dialogSelectUserVisible" />
    </el-dialog>
    <!-- 动态图集 -->
    <el-dialog title="评论图集" :visible.sync="dialogLookCommentGalleryListVisible" :modal="false" :destroy-on-close="true" fullscreen>
      <DynamicCommentGalleryList :commentId="lookModel.commentId" v-if="dialogLookCommentGalleryListVisible" />
    </el-dialog>
    
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import circleManager from '@/request/circleManager.js'
import UserList from '../../user/UserList.vue'
import DynamicCommentGalleryList from './DynamicCommentGalleryList.vue'
import UploadImageNewOne from '@/components/common/subgroup/UploadImageNewOne.vue' //上传图片
export default {
  mixins: [getList],
  props: ['newsId'],
  components: {
    UserList,
    DynamicCommentGalleryList,
    UploadImageNewOne
  },
  data() {
    return {
      requst: {
        module: circleManager,
        url: 'newsCommentList',
        addUrl: 'commentNewsCommentInfo',
        updateUrl: 'updateNewsCommentInfo',
      },
      query: {
        commentContent: '', //内容
        nickName: '',
        newsId: this.newsId,
      },
      //添加
      addOrEditDialog: false,
      addOrEditTitle: '',
      addOrEditBtnLoading: false,
      editForm: {
        newsId: this.newsId,
        userId: '',
        nickName: '',
        commentId: 0,
        commentContent: '',
        addTime: '',
        selectType: 1,
        commentGalleryJson:[],
      },
      addRules: {
        userId: [{ required: true, message: '请选择用户', trigger: 'blur' }],
        commentContent: [
          { required: true, message: '请输入评论内容', trigger: 'blur' },
        ],
      },

      dialogSelectUserVisible: false,
      //查看评论图集
      dialogLookCommentGalleryListVisible: false,
      lookModel:{
        commentId:0,
      }
    }
  },
  created() {},
  methods: {
    selectUserHandle() {
      if (this.permission.selectUserList) {
        this.dialogSelectUserVisible = true
      } else {
        this.$message.error('您没有选择用户的权限')
      }
    },
    changeUserHandle(row) {
      this.editForm.userId = row.userId
      this.editForm.nickName = row.nickName
      this.dialogSelectUserVisible = false
    },
    onAdd() {
      this.editForm.selectType = 1
      this.addOrEditTitle = '添加评论'
      this.addOrEditDialog = true
    },
    replyEditInfo(row) {
      this.editForm.commentId = row.commentId;
      this.editForm.selectType = 2
      this.addOrEditTitle = '添加回复'
      this.addOrEditDialog = true
    },
    clickAddOrEditCancel() {
      this.resetAddOrEditData()
    },
    clickAddOrEditConfirm() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            if (this.editForm.selectType == 1) {
              var obj
              if (this.editForm.commentId == 0) {
                obj = {
                  newsId: this.editForm.newsId,
                  commentContent: this.editForm.commentContent,
                  userId: this.editForm.userId,
                  commentGalleryJson:JSON.stringify(this.editForm.commentGalleryJson)
                }
              } else {
                obj = {
                  commentId: this.editForm.commentId,
                  commentContent: this.editForm.commentContent,
                }
              }

              this.addOrEditBtnLoading = true
              let dataValue = JSON.parse(JSON.stringify(obj))
              let data = await this.requst.module[
                this.editForm.commentId > 0
                  ? this.requst.updateUrl
                  : this.requst.addUrl
              ](dataValue)
              if (data.code == 200) {
                this.$message({ message: data.message, type: 'success' })
                this.refresCurrentPagehData()
                this.resetAddOrEditData()
              }
            } else {
              let { code, message } = await circleManager.replyNewsCommentInfo({
                userId: this.editForm.userId,
                commentId: this.editForm.commentId,
                commentContent: this.editForm.commentContent,
              })
              if (code == 200) {
                this.$message({
                  message: message,
                  type: 'success',
                })
                this.refresCurrentPagehData()
                this.resetAddOrEditData()
              }
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.addOrEditBtnLoading = false
          }
        }
      })
    },
    resetAddOrEditData() {
      this.addOrEditDialog = false
      this.editForm.newsId = this.newsId
      this.editForm.userId = ''
      this.editForm.nickName = ''
      this.editForm.commentId = 0
      this.editForm.commentContent = ''
      this.editForm.addTime = ''
      this.editForm.commentGalleryJson = [];
    },
    async clickEditInfo(row) {
      this.editForm.newsId = row.newsId
      this.editForm.userId = row.userId
      this.editForm.nickName = row.nickName
      this.editForm.commentId = row.commentId
      this.editForm.commentContent = row.commentContent
      this.editForm.addTime = row.addTime
      this.addOrEditTitle = '修改评论'
      this.editForm.commentGalleryJson = [];
      this.addOrEditDialog = true
    },

    // 点击删除
    async deleteAdmin(row, index) {
      try {
        let { code, message } = await circleManager.removeNewsCommentInfo({
          commentId: row.commentId,
        })
        if (code === 200) {
          this.$message({
            message: message,
            type: 'success',
          })
          this.delRefresh(index)
        }
      } catch (error) {
        console.log(error)
      }
    },

    showContenHandle(row,type){
      switch (type) {
        case 1:
          this.lookModel.commentId = row.commentId;
          this.dialogLookCommentGalleryListVisible = true;
          break;
      
        default:
          break;
      }
    },

  },
}
</script>

<style scoped>
</style>
