import BaseModule from './utils/BaseModule'
class BaseManager extends BaseModule {
    constructor() {
        super()
    }
    //圈子分类列表
    circleClassInfoList(param) {
        return this.get('web/circleClassInfo/list',param)
    }
    //圈子分类新增
    saveCircleClassInfo(param) {
        return this.post('web/circleClassInfo/save',param)
    }
    //圈子分类修改
    updateCircleClassInfo(param) {
        return this.post('web/circleClassInfo/update',param)
    }
    //圈子分类删除
    removeCircleClassInfo(param) {
        return this.post('web/circleClassInfo/remove',param)
    }


    //话题列表
    topicInfoList(param) {
        return this.get('web/topicInfo/list',param)
    }
    //话题新增
    saveTopicInfo(param) {
        return this.post('web/topicInfo/save',param)
    }
    //话题修改
    updateTopicInfo(param) {
        return this.post('web/topicInfo/update',param)
    }
    //话题删除
    removeTopicInfo(param) {
        return this.post('web/topicInfo/remove',param)
    }


    //圈子列表
    circleInfoList(param) {
        return this.get('web/circleInfo/list',param)
    }
    //圈子新增
    saveCircleInfo(param) {
        return this.post('web/circleInfo/save',param)
    }
    //圈子修改
    updateCircleInfo(param) {
        return this.post('web/circleInfo/update',param)
    }
    //圈子删除
    removeCircleInfo(param) {
        return this.post('web/circleInfo/remove',param)
    }
    //圈子审核
    auditCircleInfo(param) {
        return this.post('web/circleInfo/audit',param)
    }
    //圈子是否热门
    isPopularCircleInfo(param) {
        return this.post('web/circleInfo/isPopular',param)
    }
    //圈子是否置顶
    isTopCircleInfo(param) {
        return this.post('web/circleInfo/isTop',param)
    }
    
    //积分商品列表
    complaintTypeInfoList(param) {
        return this.get('web/complaintTypeInfo/list',param)
    }
    //积分商品分类新增
    saveComplaintTypeInfo(param) {
        return this.post('web/complaintTypeInfo/save',param)
    }
    //积分商品分类修改
    updateComplaintTypeInfo(param) {
        return this.post('web/complaintTypeInfo/update',param)
    }
    //积分商品分类删除
    removeComplaintTypeInfo(param) {
        return this.post('web/complaintTypeInfo/remove',param)
    }
    
    //动态投诉列表
    newsComplaintInfoList(param) {
        return this.get('web/newsComplaintInfo/list',param)
    }
    //动态投诉图集
    complaintGalleryInfo(param) {
        return this.get('web/complaintGalleryInfo/list',param)
    }
    //处理
    updateNewsComplaintInfo(param) {
        return this.post('web/newsComplaintInfo/update',param)
    }

    //动态列表
    newsList(param) {
        return this.get('web/newsInfo/list',param)
    }
    //动态新增
    saveNewsInfo(param) {
        return this.post('web/newsInfo/save',param)
    }
    //动态修改
    updateNewsInfo(param) {
        return this.post('web/newsInfo/update',param)
    }
    //动态删除
    removeNewsInfo(param) {
        return this.post('web/newsInfo/remove',param)
    }
    //动态是否置顶
    isTopNewsInfo(param) {
        return this.post('web/newsInfo/isTop',param)
    }


    //动态话题列表
    newsTopicList(param) {
        return this.get('web/newsTopicInfo/list',param)
    }

    //动态评论
    newsCommentList(param) {
        return this.get('web/newsCommentInfo/list',param)
    }
    //动态评论删除
    removeNewsCommentInfo(param) {
        return this.post('web/newsCommentInfo/remove',param)
    }
    //修改评论/回复
    updateNewsCommentInfo(param) {
        return this.post('web/newsCommentInfo/update',param)
    }
    //添加评论
    commentNewsCommentInfo(param) {
        return this.post('web/newsCommentInfo/comment',param)
    }
    //回复评论
    replyNewsCommentInfo(param) {
        return this.post('web/newsCommentInfo/reply',param)
    }

    //动态图集
     newsGalleryInfoList(param) {
        return this.get('web/newsGalleryInfo/list',param)
    }
    //动态图集新增
    saveNewsGalleryInfo(param) {
        return this.post('web/newsGalleryInfo/save',param)
    }
    //动态图集修改
    updateNewsGalleryInfo(param) {
        return this.post('web/newsGalleryInfo/update',param)
    }
    //动态图集删除
    removeNewsGalleryInfo(param) {
        return this.post('web/newsGalleryInfo/remove',param)
    }


    //获取动态评论图集
    commentGalleryInfoList(param) {
        return this.get('web/commentGalleryInfo/list',param)
    }
    //动态评论图集新增
    saveCommentGalleryInfo(param) {
        return this.post('web/commentGalleryInfo/save',param)
    }
    //动态评论图集修改
    updateCommentGalleryInfo(param) {
        return this.post('web/commentGalleryInfo/update',param)
    }
    //动态评论图集删除
    removeCommentGalleryInfo(param) {
        return this.post('web/commentGalleryInfo/remove',param)
    }

}

export default new BaseManager()