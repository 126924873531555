<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s">
        <el-form-item>
          <el-input type="text" placeholder="请输入搜索词" v-model="query.topicName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="refreshData">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="onAdd" v-if="permission.saveTopicInfo">添加</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" plain type="primary" v-if="permission.selectTopicList" @click="selectTotalSelectRow()">批量选择</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="table" :data="tableData" border stripe :row-key="'topicId'" v-loading="showLoading" :height="tableHeight" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="40" :selectable="selectable" v-if="permission.selectTopicList" fixed="left">
        </el-table-column>
        <el-table-column prop="topicId" label="编号" align="center" width="60">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="topicName" label="话题名称" align="center"></el-table-column>
        <el-table-column prop="isShow" label="是否显示" align="center">
          <template slot-scope="scope">
            <i :class="[scope.row.isShow == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="orderWeight" label="权重值" align="center"></el-table-column>
        <el-table-column label="操作" width="110px" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="permission.updateTopicInfo">
              <el-button class="success" type="text" icon="el-icon-edit-outline" @click="clickEditInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="permission.removeTopicInfo">
              <el-popconfirm title="您确定要删除么？" icon-color="red" @confirm="deleteAdmin(scope.row, scope.$index)">
                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                </el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="选择话题" placement="top" v-if="permission.selectTopicList">
              <el-button class="success" type="text" icon="el-icon-circle-check" @click="selectTopicList(scope.row)">
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <!-- 查看话题详情 -->
    <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

    <!-- 添加话题 -->
    <el-dialog :title="`${editForm.topicId ? '编辑' : '添加'}话题`" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form label-width="120px" ref="editForm" :model="editForm" :rules="addRules">
        <el-form-item label="话题名称" prop="topicName">
          <el-input size="medium" v-model="editForm.topicName" placeholder="请输入话题名称"></el-input>
        </el-form-item>
        <el-form-item label="是否显示" prop="isShow">
          <el-checkbox v-model="editForm.isShow" :true-label="1" :false-label="0"></el-checkbox>
        </el-form-item>
        <el-form-item label="权重" prop="orderWeight">
          <el-input type="number" v-model="editForm.orderWeight" placeholder="请填写权重"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAddOrEditCancel">取 消</el-button>
        <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import circleManager from '@/request/circleManager.js'
import ShowContent from '@/components/common/subgroup/ShowContent.vue'
export default {
  mixins: [getList],
  components: {
    ShowContent,
  },
  data() {
    return {
      requst: {
        module: circleManager,
        url: 'topicInfoList',
        addUrl: 'saveTopicInfo',
        updateUrl: 'updateTopicInfo',
      },
      dialogSeeContent: false,
      rowInfo: {},
      query: {
        topicName: '', //搜索词
      },
      addOrEditDialog: false,
      addOrEditBtnLoading: false,
      editForm: {
        topicId: 0,
        topicName: '',
        isShow: 1,
        orderWeight: 9,
      },
      addRules: {
        topicName: [
          { required: true, message: '请输入话题名称', trigger: 'blur' },
        ],
        orderWeight: [
          { required: true, message: '请输入权重', trigger: 'blur' },
        ],
      },
      multipleSelection: [],
    }
  },
  created() {},
  methods: {
    hoverContent(row) {
      this.rowInfo = row
      this.dialogSeeContent = true
    },

    // 点击添加
    onAdd() {
      this.addOrEditDialog = true
    },

    // 点击添加---取消
    clickAddOrEditCancel() {
      this.resetAddOrEditData()
    },
    // 点击添加---确定
    clickAddOrEditConfirm() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            this.addOrEditBtnLoading = true
            let dataValue = JSON.parse(JSON.stringify(this.editForm))
            let data = await this.requst.module[
              this.editForm.topicId > 0
                ? this.requst.updateUrl
                : this.requst.addUrl
            ](dataValue)
            if (data.code == 200) {
              this.$message({
                message: data.message,
                type: 'success',
              })
              this.refresCurrentPagehData()
              this.resetAddOrEditData()
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.addOrEditBtnLoading = false
          }
        }
      })
    },

    // 清除添加数据
    resetAddOrEditData() {
      this.addOrEditDialog = false
      this.editForm = {
        topicId: 0,
        topicName: '',
        isShow: 1,
        orderWeight: 9,
      }
    },
    // 点击编辑信息
    async clickEditInfo(row) {
      this.editForm = {
        topicId: row.topicId,
        topicName: row.topicName,
        isShow: row.isShow,
        orderWeight: row.orderWeight,
      }
      this.addOrEditDialog = true
    },

    // 点击删除
    async deleteAdmin(row, index) {
      try {
        let { code, message } = await circleManager.removeTopicInfo({
          topicId: row.topicId,
        })
        if (code === 200) {
          this.$message({
            message: message,
            type: 'success',
          })
          this.delRefresh(index)
        }
      } catch (error) {
        console.log(error)
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    selectable: function (row, index) {
      return true;
    },
    async selectTotalSelectRow() {
      if (this.multipleSelection.length == 0) {
        this.$message.error('请选择话题')
        return
      }
      this.$emit("selectTopic",this.multipleSelection)
    },
    selectTopicList(row){
      this.$emit("selectTopic",[row])
    }
  },
}
</script>

<style scoped>
</style>
