<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s warp">
                <el-form-item>
                    <el-input type="text" placeholder="请输入用户名" v-model="query.nickName"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input type="text" placeholder="请输入手机号" v-model="query.loginName"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input type="text" placeholder="请输入门店名" v-model="query.storeName"></el-input>
                </el-form-item>
                <el-form-item v-if="circleId == null" style="width:100px">
                    <el-select size="small" v-model="query.userType" placeholder="用户类型" clearable
                        @change="changeUserTypeHandle">
                        <el-option v-for="item in userTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="width:100px">
                    <el-select size="small" v-model="query.onlineState" placeholder="在线状态" clearable>
                        <el-option v-for="item in onlineStateList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="width:100px">
                    <el-select size="small" v-model="query.isRecommend" placeholder="是否推荐" clearable>
                        <el-option v-for="item in isRecommendList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="width:100px">
                    <el-select size="small" v-model="query.isUse" placeholder="是否禁用" clearable>
                        <el-option v-for="item in isUseList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="width:100px">
                    <el-select size="small" v-model="query.isOfficial" placeholder="是否官方" clearable
                        :disabled="isOfficial == 1">
                        <el-option v-for="item in isOfficialList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="width:100px">
                    <el-select size="small" v-model="query.isLogout" placeholder="是否注销" clearable>
                        <el-option v-for="item in isLogoutList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item v-if="permission.addUser">
                    <el-button type="primary" plain @click="onAdd">添加</el-button>
                </el-form-item>
                <el-form-item v-if="permission.updateLabel">
                    <el-button type="primary" plain @click="updateLabelHandle">同步企业微信标签</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'userId'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="userId" label="编号" align="center" width="60" fixed="left">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="nickName" label="昵称" min-width="120" align="center" fixed="left">
                </el-table-column>
                <el-table-column label="用户头像" width="90" align="center">
                    <template slot-scope="scope">
                        <el-image class="table-td-thumb" :src="scope.row.headImg"
                            :preview-src-list="[scope.row.headImg]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>

                <el-table-column prop="loginName" label="手机号" min-width="100" align="center"></el-table-column>
                <el-table-column prop="sex" label="性别" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.sex == 1">男</span>
                        <span v-else-if="scope.row.sex == 2">女</span>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column prop="age" label="年龄" min-width="100" align="center"></el-table-column>
                <el-table-column prop="occupationName" label="职业" min-width="100" align="center"></el-table-column>
                <el-table-column label="用户标签" align="center">
                    <template slot-scope="scope">
                        <a @click="userTagHandle(scope.row)">
                            <el-link type="primary">查看</el-link>
                        </a>
                    </template>
                </el-table-column>
                <el-table-column prop="personalSignature" label="个性签名" align="center">
                    <template slot-scope="scope">
                        <a @click="showContentHandle(scope.row, 1)">
                            <el-link type="primary">查看</el-link>
                        </a>
                    </template>
                </el-table-column>
                <el-table-column prop="userType" label="用户类型" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.userType | getStateName(userTypeList) }}
                    </template>
                </el-table-column>
                <el-table-column prop="storeName" label="门店名" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.storeName || "--" }}
                    </template>
                </el-table-column>
                <el-table-column prop="subCount" label="下级数量" min-width="100" align="center">
                    <template slot-scope="scope">
                        <a @click="subUserHandle(scope.row)">
                            <el-link type="primary">{{ scope.row.subCount }}</el-link>
                        </a>
                    </template>
                </el-table-column>
                <el-table-column prop="integral" label="用户积分" align="center">
                    <template slot-scope="scope">
                        <a @click="userIntegralHandle(scope.row)">
                            <el-link type="primary">{{ scope.row.integral }}</el-link>
                        </a>
                    </template>
                </el-table-column>
                <!-- 2024-05-22二期新增 -->
                <el-table-column prop="accumulatedIncome" label="累积收益" align="center">
                    <template slot-scope="scope">
                        <a @click="userAccumulatedIncomeHandle(scope.row)">
                            <el-link type="primary">{{ scope.row.accumulatedIncome }}</el-link>
                        </a>
                    </template>
                </el-table-column>
                <el-table-column prop="withdrawableIncome" label="可提现收益" align="center">
                </el-table-column>
                <el-table-column prop="withdrawalIncome" label="提现收益" align="center">
                    <template slot-scope="scope">
                        <a @click="userWithdrawalIncomeHandle(scope.row)">
                            <el-link type="primary">{{ scope.row.withdrawalIncome }}</el-link>
                        </a>
                    </template>
                </el-table-column>

                <el-table-column prop="circleCount" label="圈子数" align="center">
                    <template slot-scope="scope">
                        <a @click="userCircleCountHandle(scope.row)">
                            <el-link type="primary">{{ scope.row.circleCount }}</el-link>
                        </a>
                    </template>
                </el-table-column>
                <el-table-column prop="newsCount" label="动态数" align="center">
                    <template slot-scope="scope">
                        <a @click="userNewsCountHandle(scope.row)">
                            <el-link type="primary">{{ scope.row.newsCount }}</el-link>
                        </a>
                    </template>
                </el-table-column>

                <el-table-column prop="followCount" label="关注数" align="center"></el-table-column>
                <el-table-column prop="fansCount" label="粉丝数" align="center"></el-table-column>


                <el-table-column prop="onlineState" label="在线状态" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.userType == 3">
                            {{ scope.row.onlineState | getStateName(onlineStateList) }}
                        </span>
                        <span v-else>--</span>
                    </template>
                </el-table-column>
                <el-table-column prop="isAutoOnline" label="是否开启自动上线" min-width="80" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isAutoOnline == 0 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="isRecommend" label="是否推荐" min-width="80" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isRecommend == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="isLogout" label="是否注销" min-width="80" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isLogout == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="isOfficial" label="是否官方" min-width="80" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isOfficial == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="isUse" label="是否可用" min-width="80" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isUse == 0 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="registTime" label="注册时间" width="140" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.registTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180px" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content='scope.row.isUse === 0 ? "禁用" : "解禁"'
                            placement="top" v-if="permission.disableUser">
                            <el-popconfirm title="您确定要修改用户禁用状态吗？" icon-color="red"
                                @confirm="setEditIsUseHandle(scope.row)">
                                <el-button slot="reference"
                                    :icon='scope.row.isUse === 0 ? "el-icon-close" : "el-icon-check"' type="text"
                                    class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" :content='scope.row.isOfficial === 1 ? "取消官方" : "设置官方"'
                            placement="top" v-if="permission.setOfficial">
                            <el-popconfirm title="您确定要修改用户是否官方状态吗？" icon-color="red"
                                @confirm="setOfficialHandle(scope.row)">
                                <el-button slot="reference"
                                    :icon='scope.row.isOfficial === 1 ? "el-icon-s-custom" : "el-icon-s-custom"'
                                    type="text" class="success">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" :content='scope.row.isRecommend === 1 ? "取消推荐" : "设置推荐"'
                            placement="top" v-if="permission.setRecommend">
                            <el-popconfirm title="您确定要修改用户是否推荐状态吗？" icon-color="red"
                                @confirm="setRecommendHandle(scope.row)">
                                <el-button slot="reference"
                                    :icon='scope.row.isRecommend === 1 ? "el-icon-download" : "el-icon-upload2"'
                                    type="text" class="success">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="设置用户身份" placement="top"
                            v-if="permission.setUserType">
                            <el-button class="success" type="text" icon="el-icon-user-solid"
                                @click="setUserTypeHandle(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="修改用户积分" placement="top"
                            v-if="permission.changeIntegral">
                            <el-button class="success" type="text" icon="el-icon-coin"
                                @click="changeIntegralHandle(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="选择" placement="top"
                            v-if="permission.selectUserList">
                            <el-button class="success" type="text" icon="el-icon-circle-check"
                                @click="selectUserHandle(scope.row)"></el-button>
                        </el-tooltip>

                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>

        <!-- 详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
        <!-- 用户积分明细 -->
        <el-dialog :title="`用户积分明细`" :visible.sync="dialogUserIntegralListVisible" :modal="false"
            :destroy-on-close="true" fullscreen class="absolute">
            <IntegralChangeList v-if="dialogUserIntegralListVisible" :userId="selectUserId" />
        </el-dialog>
        <!-- 下级用户 -->
        <el-dialog :title="`下级用户列表`" :visible.sync="dialogSubDirectListVisible" :modal="false" :destroy-on-close="true"
            fullscreen class="absolute">
            <name-list v-if="dialogSubDirectListVisible" :pUserId="selectUserId" />
        </el-dialog>
        <!-- 设置用户身份 -->
        <el-dialog :title="`设置用户身份`" :visible.sync="dialogUserTypeFormVisible" :modal="false" :destroy-on-close="true"
            fullscreen @close="resetAddOrEditData">
            <el-form label-width="150px" ref="userTypeForm" :model="userTypeForm" :rules="addRules">
                <el-form-item label="用户身份" prop="userType">
                    <el-select @change="userTypeChange" size="small" v-model="userTypeForm.userType">
                        <el-option v-for="item in userTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="门店ID" prop="storeId"
                    v-if="userTypeForm.userType == 2 || userTypeForm.userType == 4" class="is-required">
                    <el-input v-model="userTypeForm.storeId" placeholder="请选择门店" disabled style="width: 400px">
                    </el-input>
                    <el-button style="margin-left: 20px" type="primary" plain @click="createIdChangeNew">选择</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickUserTypeConfirm">确定</el-button>
            </div>
        </el-dialog>
        <!-- 选择门店 -->
        <el-dialog title="选择门店" :visible.sync="dialogStoreFormVisible" :modal="false" :destroy-on-close="true"
            fullscreen class="absolute">
            <StoreList :key="'key' + currentKey" @changeSelectStore="handleChangeSelectStore"
                v-if="dialogStoreFormVisible">
            </StoreList>
        </el-dialog>
        <!-- 用户动态列表 -->
        <el-dialog title="用户动态列表" :visible.sync="dialogLookDynamicVisible" :modal="false" :destroy-on-close="true"
            fullscreen>
            <DynamicList :userId="selectUserId" v-if="dialogLookDynamicVisible" />
        </el-dialog>
        <!-- 用户圈子列表 -->
        <el-dialog title="用户圈子列表" :visible.sync="dialogLookCircleVisible" :modal="false" :destroy-on-close="true"
            fullscreen>
            <CircleList :userId="selectUserId" v-if="dialogLookCircleVisible" />
        </el-dialog>
        <!-- 修改用户积分 -->
        <el-dialog title="修改用户积分" :visible.sync="dialogEditUserIntegralFormVisible" :modal="false"
            :destroy-on-close="true" fullscreen @close="resetEditIntegralData">
            <el-form label-width="120px" ref="editUserIntegralModel" :model="editUserIntegralModel"
                :rules="editIntegralRules">
                <el-form-item label="当前积分:" prop="userFees">
                    <el-input size="medium" style="width: 400px" v-model="editUserIntegralModel.userIntegrals"
                        prefix-icon="el-icon-coin" placeholder="" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="类型:">
                    <el-select size="small" v-model="editUserIntegralModel.type">
                        <el-option v-for="item in editIntegralTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="变动积分:" prop="integral" class="is-required">
                    <el-input size="medium" style="width: 400px" v-model="editUserIntegralModel.integral" type="number"
                        prefix-icon="el-icon-coin" placeholder="请输入变动积分"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickEditIntegralCancel">取 消</el-button>
                <el-button type="primary" :loading="editUserIntegralBtnLoading" @click="editUserIntegralConfirm">确定
                </el-button>
            </div>
        </el-dialog>
        <!-- 添加用户 -->
        <el-dialog :title="`${'添加'}用户`" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true"
            fullscreen @close="resetAddOrEditData">
            <el-form label-width="120px" ref="editForm" :model="editForm" :rules="addRules">
                <el-form-item label="用户昵称" prop="nickName">
                    <el-input size="medium" v-model="editForm.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item>
                <el-form-item label="用户头像" prop="headImg" class="is-required">
                    <UploadImageNewOne :limit="1" :imgList.sync="editForm.headImg" accept="image/*">
                    </UploadImageNewOne>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddUserConfirm">确定</el-button>
            </div>
        </el-dialog>
        <!-- 累积收益 -->
        <el-dialog title="余额变动记录" :visible.sync="dialogBalanceChangeListVisible" :modal="false" :destroy-on-close="true"
            fullscreen>
            <BalanceChangeList :userId="selectUserId" v-if="dialogBalanceChangeListVisible" />
        </el-dialog>
        <!-- 提现收益 -->
        <el-dialog title="提现记录" :visible.sync="dialogWithdrawalListVisible" :modal="false" :destroy-on-close="true"
            fullscreen>
            <WithdrawalList :userId="selectUserId" v-if="dialogWithdrawalListVisible" />
        </el-dialog>
        <!-- 用户标签 -->
        <el-dialog title="用户标签" :visible.sync="dialogUserTagListVisible" :modal="false" :destroy-on-close="true"
            fullscreen>
            <UserTagList :userId="selectUserId" v-if="dialogUserTagListVisible" />
        </el-dialog>

    </div>
</template>

<script>
import { getList } from "@/tools/getList.js";
import userManager from "@/request/userManager.js";
import IntegralChangeList from "@/components/page/user/IntegralChangeList.vue";
import ShowContent from "@/components/common/subgroup/ShowContent.vue";
import StoreList from "@/components/page/store/StoreList.vue";
import DynamicList from "@/components/page/circle/dynamic/DynamicList.vue";
import CircleList from "@/components/page/circle/list/CircleList.vue";
import UploadImageNewOne from "@/components/common/subgroup/UploadImageNewOne.vue"; //上传图片
import BalanceChangeList from "@/components/page/user/BalanceChangeList.vue";
import WithdrawalList from "@/components/page/user/WithdrawalList.vue";
import UserTagList from "@/components/page/user/UserTagList.vue";

export default {
    name: "name-list",
    mixins: [getList],
    components: {
        ShowContent,
        IntegralChangeList,
        StoreList,
        DynamicList,
        CircleList,
        UploadImageNewOne,
        BalanceChangeList,
        WithdrawalList,
        UserTagList,
    },
    props: ["pUserId", "circleId", "isOfficial"],
    data() {
        var validateIntegralReason = (rule, value, callback) => {
            if (value === '' || value == 0) {
                callback(new Error('请输入变动积分'))
            } else if (this.editUserIntegralModel.type == 0) {
                if (this.editUserIntegralModel.userIntegrals < value * 1) {
                    callback(new Error('扣除积分不能大于用户剩余积分'))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        }
        return {
            requst: {
                module: userManager,
                url: "userList",
                disableUrl: "disableUser",
                setOfficialUrl: "setOfficial",
                setRecommendUrl: "setRecommend",
                setUserTypeUrl: "setUserType",
                setUserTypeSupervisorUrl: "setUserTypeSupervisor",
            },
            query: {
                nickName: "",
                loginName: "",
                userType: "",
                isRecommend: "",
                isUse: "",
                isOfficial: this.isOfficial == null ? '' : this.isOfficial,
                isLogout: "",
                onlineState: "",
                storeName: "",
                circleId: this.circleId == null ? '' : this.circleId,
                pUserId: this.pUserId == null ? '' : this.pUserId,
            },
            // 【1：普通用户；2:护理师；3:专家】
            userTypeList: [
                {
                    value: 1,
                    label: "普通用户",
                },
                {
                    value: 2,
                    label: "护理师",
                },
                {
                    value: 3,
                    label: "专家",
                },
                {
                    value: 4,
                    label: "主管",
                },

            ],
            isRecommendList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
            isUseList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
            isOfficialList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
            isLogoutList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
            // onlineStateList: [{ value: 0, label: "不在线", }, { value: 1, label: "在线", }],
            onlineStateList: [],
            //
            dialogSeeContent: false,
            rowInfo: {
                title: "",
                content: "",
            },
            addRules: {
                storeId: [{ required: true, message: "请选择门店", trigger: "blur" }],
                changeAmount: [
                    { required: true, message: "请输入变动金额", trigger: "blur" },
                    { pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/, message: "变动金额必须大于0", trigger: "change", },
                ],
                changeNumber: [
                    { required: true, message: "请输入变动数量", trigger: "blur" },
                    { pattern: /^[1-9][0-9]*$/, message: "变动数量必须为正整数", trigger: "change", },
                ],
                headImg: [{ required: true, message: "请上传用户头像", trigger: "blur" }],
                nickName: [
                    { required: true, message: "请输入用户昵称", trigger: "blur" },
                ],
            },
            //
            selectUserId: 0,
            dialogSubDirectListVisible: false,
            //
            addOrEditBtnLoading: false,
            userTypeForm: {
                userId: 0,
                userType: '',
                storeId: '',
            },
            dialogUserTypeFormVisible: false,
            dialogUserIntegralListVisible: false,
            currentKey: 1,
            dialogStoreFormVisible: false,
            //
            dialogLookDynamicVisible: false,
            dialogLookCircleVisible: false,
            //修改积分
            editUserIntegralBtnLoading: false,
            dialogEditUserIntegralFormVisible: false,
            editUserIntegralModel: {
                id: 0,
                integral: '',
                userIntegrals: 0,
                type: 1,
            },
            editIntegralTypeList: [
                {
                    value: 1,
                    label: '系统赠送积分',
                },
                {
                    value: 0,
                    label: '系统扣除积分',
                },
            ],
            editIntegralRules: {
                ///^\+?[1-9]\d*$/
                integral: [
                    { validator: validateIntegralReason, trigger: 'blur' },
                    {
                        pattern: /^[1-9][0-9]*$/,
                        message: '变动数量必须为正整数',
                        trigger: 'change',
                    },
                ],
            },
            //
            addOrEditDialog: false,
            editForm: {
                nickName: "",
                headImg: "",
            },
            // 
            dialogBalanceChangeListVisible: false,
            dialogWithdrawalListVisible: false,
            dialogUserTagListVisible: false,

        };
    },
    methods: {
        changeUserTypeHandle(val) {
            if (val == 3) {
                this.onlineStateList = [{ value: 0, label: "不在线", }, { value: 1, label: "在线", }]
            } else {
                this.onlineStateList = []
                this.query.onlineState = ''
            }
        },
        showContentHandle(row, type) {
            switch (type) {
                case 1:
                    this.rowInfo.title = "个性签名";
                    this.rowInfo.content = row.personalSignature;
                    this.dialogSeeContent = true;
                    break;

                default:
                    break;
            }
        },
        integralListHandle(row) {
            this.selectUserId = row.userId;
            this.dialogIntegralFormVisible = true;
        },
        // 禁用/解禁
        async setEditIsUseHandle(row) {
            try {
                let data = await this.requst.module[this.requst.disableUrl]({ userId: row.userId, isUse: row.isUse > 0 ? 0 : 1 })
                if (data.code == 200) {
                    this.$message({
                        message: data.message,
                        type: 'success'
                    });
                    this.$emit('success')
                    this.refreshData()
                }
            } catch (error) {
                console.log(error)
            }
        },
        // 设置用户为官方用户
        async setOfficialHandle(row) {
            try {
                let data = await this.requst.module[this.requst.setOfficialUrl]({ userId: row.userId, isOfficial: row.isOfficial > 0 ? 0 : 1 })
                if (data.code == 200) {
                    this.$message({
                        message: data.message,
                        type: 'success'
                    });
                    this.$emit('success')
                    this.refreshData()
                }
            } catch (error) {
                console.log(error)
            }
        },
        // 设置用户是否推荐
        async setRecommendHandle(row) {
            try {
                let data = await this.requst.module[this.requst.setRecommendUrl]({ userId: row.userId, isRecommend: row.isRecommend > 0 ? 0 : 1 })
                if (data.code == 200) {
                    this.$message({
                        message: data.message,
                        type: 'success'
                    });
                    this.$emit('success')
                    this.refreshData()
                }
            } catch (error) {
                console.log(error)
            }
        },
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        resetAddOrEditData() {
            this.dialogUserTypeFormVisible = false
            this.userTypeForm = {
                userId: 0,
                userType: '',
                storeId: '',
            }
            //
            this.addOrEditDialog = false
            this.editForm = {
                nickName: "",
                headImg: "",
            }
        },
        // 下级用户列表
        subUserHandle(row) {
            this.selectUserId = row.userId;
            this.dialogSubDirectListVisible = true;
        },
        //用户身份
        setUserTypeHandle(row) {
            this.userTypeForm.userId = row.userId;
            this.userTypeForm.userType = row.userType;
            this.userTypeForm.storeId = row.storeId > 0 ? row.storeId : '';
            this.dialogUserTypeFormVisible = true
        },
        userTypeChange() {
            this.userTypeForm.storeId = ''
        },
        clickUserTypeConfirm() {
            this.$refs.userTypeForm.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        if (this.userTypeForm.storeId == '') {
                            this.userTypeForm.storeId = 0
                        }
                        let dataValue = JSON.parse(JSON.stringify(this.userTypeForm))
                        let data = await this.requst.module[
                            this.userTypeForm.userType == 4
                                ? this.requst.setUserTypeSupervisorUrl
                                : this.requst.setUserTypeUrl
                        ](dataValue)
                        // userManager.setUserType(dataValue)
                        if (data.code == 200) {
                            this.$message({
                                message: data.message,
                                type: 'success'
                            });
                            this.refreshData()
                            this.resetAddOrEditData()
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }

                }
            })
        },
        createIdChangeNew() {
            this.dialogStoreFormVisible = true;
        },
        handleChangeSelectStore(store) {
            console.log(store);
            this.userTypeForm.storeId = store.storeId;
            this.dialogStoreFormVisible = false;
        },
        //用户积分
        userIntegralHandle(row) {
            if (this.permission.integralList) {
                this.selectUserId = row.userId;
                this.dialogUserIntegralListVisible = true;
            } else {
                this.$message.error("您没有查看用户积分的权限")
            }
        },
        //动态数量
        userNewsCountHandle(row) {
            if (this.permission.lookDynamic) {
                this.selectUserId = row.userId;
                this.dialogLookDynamicVisible = true;
            } else {
                this.$message.error("您没有查看用户动态的权限")
            }
        },
        //圈子数量
        userCircleCountHandle(row) {
            if (this.permission.lookCircle) {
                this.selectUserId = row.userId;
                this.dialogLookCircleVisible = true;
            } else {
                this.$message.error("您没有查看用户圈子的权限")
            }
        },
        //修改用户积分
        changeIntegralHandle(row) {
            this.editUserIntegralModel.id = row.userId
            this.editUserIntegralModel.userIntegrals = row.integral
            this.dialogEditUserIntegralFormVisible = true
        },
        clickEditIntegralCancel() {
            this.resetEditIntegralData()
        },
        resetEditIntegralData() {
            this.dialogEditUserIntegralFormVisible = false
            this.editUserIntegralModel.id = 0
            this.editUserIntegralModel.type = 1
            this.editUserIntegralModel.userIntegrals = 0
            this.editUserIntegralModel.integral = ''
        },
        async editUserIntegralConfirm() {
            this.$refs.editUserIntegralModel.validate(async (valid) => {
                if (valid) {
                    var obj = {
                        userId: this.editUserIntegralModel.id,
                        changeIntegral: this.editUserIntegralModel.integral,
                        isIncome: this.editUserIntegralModel.type,
                    }
                    try {
                        this.editUserIntegralBtnLoading = true
                        let data = await userManager.changeIntegral(obj)
                        if (data.code == 200) {
                            this.$message({ message: data.message, type: 'success' })
                            this.refresCurrentPagehData()
                            this.resetEditIntegralData()
                        }
                    } catch (error) {
                        //
                    } finally {
                        this.editUserIntegralBtnLoading = false
                    }
                } else {
                    return false
                }
            })
        },

        // 点击添加
        onAdd() {
            this.addOrEditDialog = true
        },

        // 点击添加---确定
        clickAddUserConfirm() {
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        let dataValue = JSON.parse(JSON.stringify(this.editForm))
                        let data = await userManager.addUser(dataValue)
                        if (data.code == 200) {
                            this.$message({
                                message: data.message,
                                type: 'success'
                            });
                            this.$emit('success')
                            this.refreshData()
                            this.resetAddOrEditData()
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }

                }
            })
        },

        //选择
        selectUserHandle(row) {
            this.$emit("selectUser", row);
        },

        // 累积收益
        userAccumulatedIncomeHandle(row) {
            this.handlePermission()
            if (this.permission.lookBalanceChangeList) {
                this.selectUserId = row.userId;
                this.dialogBalanceChangeListVisible = true;
            } else {
                this.$message.error('您没有权限查看')
            }
        },
        // 提现收益
        userWithdrawalIncomeHandle(row) {
            this.handlePermission()
            if (this.permission.lookWithdrawalList) {
                this.selectUserId = row.userId;
                this.dialogWithdrawalListVisible = true;
            } else {
                this.$message.error('您没有权限查看')
            }
        },
        // 用户标签
        userTagHandle(row) {
            console.log("row");
            this.handlePermission()
            if (this.permission.getUserMessage) {
                this.selectUserId = row.userId;
                this.dialogUserTagListVisible = true;
            } else {
                this.$message.error('您没有权限查看')
            }
        },

        updateLabelHandle() {
            this.$confirm('是否同步企业微信标签？', '提示', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                type: 'warning'
            }).then(async () => {
                try {
                    let data = await userManager.updateLabel()
                    if (data.code == 200) {
                        this.$message.success(data.message)
                    }
                } catch (error) {
                    //
                }
            }).catch(() => {

            });
        }

    },
}
</script>

<style lang="scss" scoped>
.table-td-thumb {
    display: block;
    margin: auto;
    max-width: 38px;
    max-height: 38px;
    font-size: 0.2rem;
}

.image-slot {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f7fa;
}
</style>