<template>
  <div id="mapContainer"></div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  props: ['latitude', 'longitude'],
  data() {
    return {
      map: null,
      AMap: null,
      markerList: [],
    }
  },
  watch: {
    latitude: {
      handler() {
        this.changeMarker()
      },
    },
  },
  mounted() {
    this.initMap()
  },
  methods: {
    changeMarker() {
      if (this.latitude > 0 && this.longitude > 0) {
        var marker = new this.AMap.Marker({
          position: new this.AMap.LngLat(this.longitude, this.latitude), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          id: 3,
        })
        if (this.markerList.length) {
          this.map.remove(this.markerList[0])
          this.markerList = []
        }
        this.map.setCenter([this.longitude, this.latitude])
        this.markerList.push(marker)
        this.map.add(marker)
      }
    },

    initMap() {
      var that = this
      AMapLoader.load({
        key: '6aed352b5dc233b3cb1909d5e2dc0371', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.Geocoder',
          'AMap.AutoComplete',
          'Geocoder',
          'AutoComplete',
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: '1.1', // AMapUI 版本
          plugins: ['overlay/SimpleMarker'], // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: '2.0', // Loca 版本
        },
      })
        .then((AMap) => {
          that.AMap = AMap
          that.$emit('getAMap', that.AMap)
          that.map = new AMap.Map('mapContainer', {
            zoom: 15,
          })
          this.changeMarker()
          that.map.on('click', function (ev) {
            let { lng, lat } = ev.lnglat
            that.AMap.plugin('AMap.Geocoder', function () {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: '全国',
              })
              // 使用geocoder做地理/逆地理编码
              geocoder.getAddress([lng, lat], function (status, result) {
                if (status === 'complete' && result.info === 'OK') {
                  if (result && result.regeocode) {
                    if (result.regeocode.addressComponent.building.length) {
                      that.$emit('clickMapHandle', {
                        lng: lng,
                        lat: lat,
                        addressDetail:
                          result.regeocode.addressComponent.building,
                      })
                    } else {
                      var str = result.regeocode.formattedAddress
                      // if (
                      //   str.indexOf(
                      //     result.regeocode.addressComponent.province
                      //   ) != -1
                      // ) {
                      //   str = str.substring(
                      //     str.indexOf(
                      //       result.regeocode.addressComponent.province
                      //     ) + result.regeocode.addressComponent.province.length
                      //   )
                      // }
                      // if (
                      //   str.indexOf(
                      //     result.regeocode.addressComponent.city
                      //   ) != -1
                      // ) {
                      //   str = str.substring(
                      //     str.indexOf(
                      //       result.regeocode.addressComponent.city
                      //     ) + result.regeocode.addressComponent.city.length
                      //   )
                      // }
                      // if (
                      //   str.indexOf(
                      //     result.regeocode.addressComponent.district
                      //   ) != -1
                      // ) {
                      //   str = str.substring(
                      //     str.indexOf(
                      //       result.regeocode.addressComponent.district
                      //     ) + result.regeocode.addressComponent.district.length
                      //   )
                      // }
                      //  if (
                      //   str.indexOf(
                      //     result.regeocode.addressComponent.township
                      //   ) != -1
                      // ) {
                      //   str = str.substring(
                      //     str.indexOf(
                      //       result.regeocode.addressComponent.township
                      //     ) + result.regeocode.addressComponent.township.length
                      //   )
                      // }
                      // if (
                      //   str.indexOf(
                      //     result.regeocode.addressComponent.street
                      //   ) != -1
                      // ) {
                      //   str = str.substring(
                      //     str.indexOf(
                      //       result.regeocode.addressComponent.street
                      //     ) + result.regeocode.addressComponent.street.length
                      //   )
                      // }
                      // console.log(str)
                      that.$emit('clickMapHandle', {
                        lng: lng,
                        lat: lat,
                        addressDetail: str,
                      })
                    }
                  }
                }
              })
            })
          })
        })
        .catch((e) => {
          console.error(e) //加载错误提示
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.mapContainer {
  width: 100%;
  height: 380px;
}
</style>
