<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s">
        <el-form-item>
          <el-input type="text" placeholder="请输入动态标题" v-model="query.newsTitle"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="请输入圈子名称" v-model="query.circleName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="请输入用户名称" v-model="query.nickName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.isTop" placeholder="请选择是否置顶" clearable>
            <el-option v-for="item in searchForm.isTopList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.isOfficial" placeholder="请选择是否官方" clearable>
            <el-option v-for="item in searchForm.isOfficialList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.newsType" placeholder="请选择动态类型" clearable>
            <el-option v-for="item in searchForm.newsTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="refreshData">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="onAdd" v-if="permission.saveNewsInfo">添加</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="table" :data="tableData" border stripe :row-key="'newsId'" v-loading="showLoading" :height="tableHeight">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-descriptions class="margin-top" title="动态地址" :column="3" border>
              <el-descriptions-item>
                <template slot="label">
                  经度
                </template>
                {{props.row.longitude}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  纬度
                </template>
                {{props.row.latitude}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  定位地址
                </template>
                {{props.row.locationAddress}}
              </el-descriptions-item>
            </el-descriptions>
            <!-- <el-descriptions class="margin-top" title="视频信息" :column="2" border v-if="props.row.newsType == 2">
              <el-descriptions-item>
                <template slot="label">
                  视频封面
                </template>
                <el-image class="table-td-thumb" :src="props.row.videoImp" style="max-width:40px;max-heigth:40px" :preview-src-list="[props.row.videoImp]" fit="cover">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  视频地址
                </template>
                <span @click="showContenHandle(props.row, 5)">
                  <el-link type="primary">{{props.row.videoAddress}}</el-link>
                </span>
              </el-descriptions-item>
            </el-descriptions> -->
          </template>
        </el-table-column>
        <el-table-column prop="newsId" label="编号" align="center" width="50">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="newsTitle" label="动态标题" align="center" min-width="140px">
          <template slot-scope="scope">
            {{scope.row.newsTitle.length > 9 ? scope.row.newsTitle.substr(0, 3) + "..." + scope.row.newsTitle.substr(scope.row.newsTitle.length - 3, scope.row.newsTitle.length - 1) : scope.row.newsTitle}}
          </template>
        </el-table-column>
        <el-table-column prop="circleName" label="圈子名称" align="center" min-width="120px"></el-table-column>
        <el-table-column prop="nickName" label="发布人名称" align="center" min-width="110px"></el-table-column>
        <el-table-column prop="newsType" label="动态类型" align="center" width="75">
          <template slot-scope="scope">
            {{scope.row.newsType | getStateName(searchForm.newsTypeList)}}
          </template>
        </el-table-column>
        <el-table-column prop="goodsClassName" label="动态内容" width="75" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 1)">
              <el-link type="primary">查看详情</el-link>
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="goodsClassName" label="图片/视频" width="75" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 3)" v-if="scope.row.newsType==1">
              <el-link type="primary">查看图集</el-link>
            </span>
            <span @click="showContenHandle(scope.row, 5)" v-else-if="scope.row.newsType==2">
              <el-link type="primary">查看视频</el-link>
            </span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column prop="goodsClassName" label="动态话题" width="75" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 2)">
              <el-link type="primary">详情</el-link>
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="图集" width="50" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 3)" v-if="scope.row.newsType==1">
              <el-link type="primary">详情</el-link>
            </span>
          </template>
        </el-table-column> -->
        <el-table-column label="动态评论" width="70" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 4)">
              <el-link type="primary">详情</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="isTop" label="是否置顶" align="center" width="70px">
          <template slot-scope="scope">
            <i :class="[scope.row.isTop == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="isOfficial" label="是否官方" align="center" width="70px">
          <template slot-scope="scope">
            <i :class="[scope.row.isOfficial == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="likeNumber" label="点赞数量" align="center" width="70px"></el-table-column>
        <el-table-column prop="playNumber" label="播放量" align="center" width="70px"></el-table-column>
        <el-table-column prop="addTime" label="发布时间" align="center" width="140">
          <template slot-scope="scope">
            {{scope.row.addTime | formatDate}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="110px" align="center" fixed="right">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="permission.updateNewsInfo">
              <el-button class="success" type="text" icon="el-icon-edit-outline" @click="clickEditInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="permission.removeNewsInfo">
              <el-popconfirm title="您确定要删除么？" icon-color="red" @confirm="deleteAdmin(scope.row, scope.$index)">
                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                </el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="scope.row.isTop?'取消置顶':'设置置顶'" placement="top">
              <el-popconfirm :title="scope.row.isTop?'您确定要取消置顶吗？':'您确定要设置置顶吗？'" icon-color="red" @confirm="editIsTopHandle(scope.row, scope.$index)" v-if="permission.isTopNewsInfo">
                <el-button slot="reference" icon="el-icon-download" type="text" class="danger" v-if="scope.row.isTop">
                </el-button>
                <el-button slot="reference" icon="el-icon-upload2" type="text" class="success" v-else>
                </el-button>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <!-- 查看圈子详情 -->
    <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

    <!-- 添加动态 -->
    <el-dialog :title="`${editForm.newsId ? '编辑' : '添加'}动态`" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form label-width="120px" ref="editForm" :model="editForm" :rules="addRules">
        <el-form-item label="动态标题" :prop="editForm.newsType == 3?'newsTitle':''">
          <el-input size="medium" v-model="editForm.newsTitle" placeholder="请输入动态标题"></el-input>
        </el-form-item>
        <el-form-item label="动态类型" prop="newsType" v-if="editForm.newsId==0">
          <el-select size="small" v-model="editForm.newsType" placeholder="请选择动态类型" @change="changeNewsTypeHandle">
            <el-option v-for="item in searchForm.newsTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择圈子" prop="circleId" >
          <el-button @click="selectCircleHandle">选择圈子</el-button>
          <el-input style="margin-top:10px" placeholder="请选择圈子" v-model="editForm.circleName" disabled></el-input>
        </el-form-item>
        <el-form-item label="选择发布用户" prop="userId" v-if="editForm.newsId==0">
          <el-button @click="selectUserHandle">选择用户</el-button>
          <el-input style="margin-top:10px" placeholder="请选择用户" v-model="editForm.userName" disabled></el-input>
        </el-form-item>
        <el-form-item label="选择话题" prop="newsTopicInfoJson" v-if="editForm.newsId==0">
          <el-button @click="selectTopicHandle">选择话题</el-button>
          <el-button @click="clearTopicHandle">重置</el-button>
          <el-input style="margin-top:10px" placeholder="请选择话题" v-model="editForm.newsTopicName" disabled></el-input>
        </el-form-item>
        <el-form-item label="图文内容" prop="newsContent" v-if=" editForm.newsType == 3">
          <Editor v-if="addOrEditDialog && editForm.newsType == 3" v-model="editForm.newsContent"></Editor>
        </el-form-item>
        <el-form-item label="内容" prop="newsContent" v-if=" editForm.newsType == 1 || editForm.newsType==2">
          <el-input type='textarea' placeholder="请输入内容" v-model="editForm.newsContent"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="newsGalleryInfoJson" v-if="editForm.newsId==0 && editForm.newsType == 1">
          <UploadImageNewOne :imgList.sync="editForm.galleryList" accept="image/*"></UploadImageNewOne>
        </el-form-item>
        <el-form-item label="视频封面" prop="videoImp" v-if="editForm.newsType == 2">
          <UploadImageNewOne :imgList.sync="editForm.videoImp" :limit="1" accept="image/*"></UploadImageNewOne>
        </el-form-item>
        <el-form-item label="视频地址" prop="videoAddress" v-if="editForm.newsType == 2">
          <UploadFileNewOne :fileList.sync="editForm.videoAddress" :limit="1" :accept="'video/*'"></UploadFileNewOne>
        </el-form-item>
        <el-form-item label="位置信息" prop="locationAddress">
          <el-autocomplete v-model="editForm.locationAddress" :fetch-suggestions="querySearchAsync" placeholder="请填写详细地址" @select="handleSelect" style="width: 100%"></el-autocomplete>
        </el-form-item>
        <el-form-item label="地图">
          <div class="mapContainer">
            <MapView :latitude="this.editForm.latitude" :longitude="this.editForm.longitude" @getAMap="getAMapHandle" @clickMapHandle="clickMapHandle" v-if="addOrEditDialog" />
          </div>
        </el-form-item>
        <el-form-item label="经度" prop="longitude">
          <el-input size="medium" v-model="editForm.longitude" placeholder="经度" disabled></el-input>
        </el-form-item>
        <el-form-item label="纬度" prop="latitude">
          <el-input size="medium" v-model="editForm.latitude" placeholder="纬度" disabled></el-input>
        </el-form-item>

        <el-form-item label="是否置顶" prop="isTop">
          <el-checkbox v-model="editForm.isTop" :true-label="1" :false-label="0"></el-checkbox>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAddOrEditCancel">取 消</el-button>
        <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
      </div>
    </el-dialog>
    <!-- 动态话题 -->
    <el-dialog title="动态话题" :visible.sync="dialogLookNewsTopicVisible" :modal="false" :destroy-on-close="true" fullscreen>
      <DynamicTopicList :newsId="lookModel.newsId" v-if="dialogLookNewsTopicVisible" />
    </el-dialog>
    <!-- 动态评论 -->
    <el-dialog title="动态评论 " :visible.sync="dialogLookNewsCommnentVisible" :modal="false" :destroy-on-close="true" fullscreen>
      <DynamicCommenList :newsId="lookModel.newsId" v-if="dialogLookNewsCommnentVisible" />
    </el-dialog>
    <!-- 动态图集 -->
    <el-dialog title="动态图集" :visible.sync="dialogLookGalleryVisible" :modal="false" :destroy-on-close="true" fullscreen>
      <DynamicGalleryList :newsId="lookModel.newsId" v-if="dialogLookGalleryVisible" />
    </el-dialog>
    <!-- 选择圈子 -->
    <el-dialog title="选择圈子" :visible.sync="dialogSelectCircleVisible" :modal="false" :destroy-on-close="true" fullscreen class="absolute">
      <CircleList @selectCircle="changeCircleHandle" v-if="dialogSelectCircleVisible" />
    </el-dialog>
    <!-- 选择用户 -->
    <el-dialog title="选择用户" :visible.sync="dialogSelectUserVisible" :modal="false" :destroy-on-close="true" fullscreen class="absolute">
      <UserList @selectUser="changeUserHandle" v-if="dialogSelectUserVisible" />
    </el-dialog>
    <!-- 选择话题 -->
    <el-dialog title="选择话题" :visible.sync="dialogSelectTopicVisible" :modal="false" :destroy-on-close="true" fullscreen class="absolute">
      <TopicList @selectTopic="changeTopicHandle" v-if="dialogSelectTopicVisible" />
    </el-dialog>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import circleManager from '@/request/circleManager.js'
import ShowContent from '@/components/common/subgroup/ShowContent.vue'
import DynamicTopicList from './DynamicTopicList.vue'
import DynamicCommenList from './DynamicCommenList.vue'
import DynamicGalleryList from './DynamicGalleryList.vue'
import Editor from '@/components/common/subgroup/Editor.vue' //编辑器
import UploadImageNewOne from '@/components/common/subgroup/UploadImageNewOne.vue' //上传图片
import UploadFileNewOne from '@/components/common/subgroup/UploadFileNewOne.vue' //上传图片
import MapView from './sub/MapView.vue'
import UserList from '../../user/UserList.vue'
import CircleList from '../list/CircleList.vue'
import TopicList from '../topic/TopicList.vue'
export default {
  mixins: [getList],
  components: {
    ShowContent,
    DynamicTopicList,
    DynamicCommenList,
    DynamicGalleryList,
    Editor,
    UploadImageNewOne,
    UploadFileNewOne,
    MapView,
    UserList,
    CircleList,
    TopicList,
  },
  props: ['userId', 'circleId'],
  data() {
    return {
      requst: {
        module: circleManager,
        url: 'newsList',
        addUrl: 'saveNewsInfo',
        updateUrl: 'updateNewsInfo',
      },
      dialogSeeContent: false,
      rowInfo: {},
      query: {
        newsTitle: '', //搜索词
        circleName: '',
        nickName: '',
        isTop: '',
        isOfficial: '',
        newsType: '',
        circleId: this.circleId,
        userId: this.userId,
      },
      searchForm: {
        isTopList: [
          {
            value: 0,
            label: '否',
          },
          {
            value: 1,
            label: '是',
          },
        ],
        isOfficialList: [
          {
            value: 0,
            label: '否',
          },
          {
            value: 1,
            label: '是',
          },
        ],
        newsTypeList: [
          {
            value: 1,
            label: '图片',
          },
          {
            value: 2,
            label: '视频',
          },
          {
            value: 3,
            label: '图文',
          },
        ],
        topicList: [],
      },

      //添加
      addOrEditDialog: false,
      addOrEditBtnLoading: false,
      editForm: {
        newsId: 0,
        circleId: '',
        circleName: '',
        userId: '',
        userName: '',
        newsTitle: '',
        newsContent: '',
        locationAddress: '',
        longitude: '',
        latitude: '',
        videoAddress: '',
        videoImp: '',
        newsType: '',
        galleryList: [],
        newsGalleryInfoJson: '',
        newsTopicList: [],
        newsTopicName: '',
        newsTopicInfoJson: '',
      },
      addRules: {
        newsTitle: [
          { required: true, message: '请输入动态标题', trigger: 'blur' },
        ],
        newsContent: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        // locationAddress: [
        //   { required: true, message: '请输入动态地址', trigger: 'blur' },
        // ],
        // longitude: [{ required: true, message: '请选择地址', trigger: 'blur' }],
        // latitude: [{ required: true, message: '请选择地址', trigger: 'blur' }],
        videoImp: [
          { required: true, message: '请上传视频封面', trigger: 'blur' },
        ],
        videoAddress: [
          { required: true, message: '请上传视频地址', trigger: 'blur' },
        ],
        newsType: [
          { required: true, message: '请选择动态类型', trigger: 'blur' },
        ],
        newsGalleryInfoJson: [
          { required: true, message: '请上传图集', trigger: 'blur' },
        ],
        newsTopicInfoJson: [
          { required: true, message: '请选择话题', trigger: 'blur' },
        ],
        circleId: [{ required: true, message: '请选择圈子', trigger: 'blur' }],
        userId: [{ required: true, message: '请选择用户', trigger: 'blur' }],
      },

      //查看
      dialogLookNewsTopicVisible: false,
      dialogLookNewsCommnentVisible: false,
      dialogLookGalleryVisible: false,
      dialogSelectUserVisible: false,
      dialogSelectCircleVisible: false,
      dialogSelectTopicVisible: false,
      lookModel: {
        newsId: 0,
      },
      AMap: null,
    }
  },
  created() {
    this.handlePermission()
    if (this.permission.selectTopicList) {
      this.getTopicList()
    }
  },
  methods: {
    async getTopicList() {
      //
      try {
        let data = await circleManager.newsTopicList()
        if (data.code == 200) {
          this.searchForm.topicList = data.data
        }
      } catch (error) {
        //
      }
    },
    showContenHandle(row, type) {
      switch (type) {
        case 1:
          this.rowInfo.title = '动态内容'
          this.rowInfo.content = row.newsContent
          this.dialogSeeContent = true
          break
        case 2:
          if (this.permission.lookNewsTopicList) {
            this.lookModel.newsId = row.newsId
            this.dialogLookNewsTopicVisible = true
          } else {
            this.$message.error('您没有权限查看')
          }
          break
        case 3:
          if (this.permission.lookNewsGalleryList) {
            this.lookModel.newsId = row.newsId
            this.dialogLookGalleryVisible = true
          } else {
            this.$message.error('您没有权限查看')
          }
          break
        case 4:
          if (this.permission.lookNewsCommentList) {
            this.lookModel.newsId = row.newsId
            this.dialogLookNewsCommnentVisible = true
          } else {
            this.$message.error('您没有权限查看')
          }
          break
        case 5:
          window.open(row.videoAddress)
          break
        default:
          break
      }
    },

    // 点击添加
    onAdd() {
      this.addOrEditDialog = true
    },

    // 点击添加---取消
    clickAddOrEditCancel() {
      this.resetAddOrEditData()
    },
    // 点击添加---确定
    clickAddOrEditConfirm() {
      if (this.editForm.newsId == 0) {
        if (this.editForm.galleryList.length) {
          this.editForm.newsGalleryInfoJson = JSON.stringify(
            this.editForm.galleryList
          )
        } else {
          this.editForm.newsGalleryInfoJson = ''
        }

        if (this.editForm.newsTopicList.length) {
          this.editForm.newsTopicInfoJson = JSON.stringify(
          this.editForm.newsTopicList
        )
        } else {
          this.editForm.newsTopicInfoJson = ''
        }

        // this.editForm.newsTopicInfoJson = JSON.stringify(
        //   this.editForm.newsTopicList
        // )
      }
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            this.addOrEditBtnLoading = true
            if (this.editForm.newsId == 0) {
              let obj = {
                circleId: this.editForm.circleId,
                userId: this.editForm.userId,
                newsTitle: this.editForm.newsTitle,
                newsContent: this.editForm.newsContent,
                locationAddress: this.editForm.locationAddress,
                longitude: this.editForm.longitude?this.editForm.longitude:0,
                latitude: this.editForm.latitude?this.editForm.latitude:0,
                videoAddress: this.editForm.videoAddress,
                videoImp: this.editForm.videoImp,
                newsType: this.editForm.newsType,
                newsGalleryInfoJson: this.editForm.newsGalleryInfoJson,
                newsTopicInfoJson: this.editForm.newsTopicInfoJson,
                isTop: this.editForm.isTop,
              }
              let data = await circleManager.saveNewsInfo(obj)
              if (data.code == 200) {
                this.$message({
                  message: data.message,
                  type: 'success',
                })
                this.refresCurrentPagehData()
                this.resetAddOrEditData()
              }
            } else {
              //
              let obj = {
                circleId: this.editForm.circleId,
                newsId: this.editForm.newsId,
                isTop: this.editForm.isTop,
                newsTitle: this.editForm.newsTitle,
                newsContent: this.editForm.newsContent,
                locationAddress: this.editForm.locationAddress,
                longitude: this.editForm.longitude?this.editForm.longitude:0,
                latitude: this.editForm.latitude?this.editForm.latitude:0,
                videoAddress: this.editForm.videoAddress,
                videoImp: this.editForm.videoImp,
              }
              let data = await circleManager.updateNewsInfo(obj)
              if (data.code == 200) {
                this.$message({
                  message: data.message,
                  type: 'success',
                })
                this.refresCurrentPagehData()
                this.resetAddOrEditData()
              }
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.addOrEditBtnLoading = false
          }
        }
      })
    },

    // 清除添加数据
    resetAddOrEditData() {
      this.addOrEditDialog = false
      this.editForm = {
        newsId: 0,
        circleId: '',
        circleName: '',
        userId: '',
        userName: '',
        newsTitle: '',
        newsContent: '',
        locationAddress: '',
        longitude: '',
        latitude: '',
        videoAddress: '',
        videoImp: '',
        newsType: '',
        galleryList: [],
        newsGalleryInfoJson: '',
        newsTopicList: [],
        newsTopicName: '',
        newsTopicInfoJson: '',
      }
    },
    // 点击编辑信息
    async clickEditInfo(row) {
      this.editForm = {
        newsId: row.newsId,
        circleId: row.circleId,
        circleName: row.circleName,
        userId: row.userId,
        userName: row.userName,
        newsTitle: row.newsTitle,
        newsContent: row.newsContent,
        locationAddress: row.locationAddress,
        longitude: row.longitude,
        latitude: row.latitude,
        videoAddress: row.videoAddress,
        videoImp: row.videoImp,
        newsType: row.newsType,
        galleryList: [],
        newsGalleryInfoJson: '',
        newsTopicList: [],
        newsTopicName: '',
        newsTopicInfoJson: '',
      }
      this.addOrEditDialog = true
    },

    // 点击删除
    async deleteAdmin(row, index) {
      try {
        let { code, message } = await circleManager.removeNewsInfo({
          newsId: row.newsId,
        })
        if (code === 200) {
          this.$message({
            message: message,
            type: 'success',
          })
          this.delRefresh(index)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async editIsTopHandle(row) {
      try {
        let data = await circleManager.isTopNewsInfo({
          newsId: row.newsId,
          isTop: row.isTop ? 0 : 1,
        })
        if (data.code == 200) {
          this.$message.success(data.message)
          this.refresCurrentPagehData()
        }
      } catch (error) {
        //
      }
    },
    clickMapHandle(obj) {
      this.editForm.latitude = obj.lat
      this.editForm.longitude = obj.lng
      this.editForm.locationAddress = obj.addressDetail
    },
    changeNewsTypeHandle() {
      this.editForm.newsContent = ''
      this.editForm.galleryList = []
      this.editForm.newsGalleryInfoJson = ''
      this.editForm.videoImp = ''
      this.editForm.videoAddress = ''
    },
    selectCircleHandle() {
      if (this.permission.selectCircleList) {
        this.dialogSelectCircleVisible = true
      } else {
        this.$message.error('您没有选择圈子的权限')
      }
    },
    changeCircleHandle(row) {
      this.editForm.circleId = row.circleId
      this.editForm.circleName = row.circleName
      this.dialogSelectCircleVisible = false
    },
    selectUserHandle() {
      if (this.permission.selectUserList) {
        this.dialogSelectUserVisible = true
      } else {
        this.$message.error('您没有选择用户的权限')
      }
    },
    changeUserHandle(row) {
      this.editForm.userId = row.userId
      this.editForm.userName = row.nickName
      this.dialogSelectUserVisible = false
    },
    //选择话题
    selectTopicHandle() {
      if (this.permission.selectTopicList) {
        this.dialogSelectTopicVisible = true
      } else {
        this.$message.error('您没有选择话题的权限')
      }
    },
    clearTopicHandle() {
      this.editForm.newsTopicList = []
      this.editForm.newsTopicName = ''
    },
    changeTopicHandle(row) {
      for (const obj of row) {
        var isHave = false
        this.editForm.newsTopicList.forEach((element) => {
          if (obj.topicId == element.topicId) {
            isHave = true
          }
        })
        if (!isHave) {
          this.editForm.newsTopicList.push({
            topicName: obj.topicName,
            topicId: obj.topicId,
          })
          this.editForm.newsTopicName += obj.topicName + ' '
        }
      }
      this.dialogSelectTopicVisible = false
    },

    // 地图
    getAMapHandle(value) {
      this.AMap = value
    },

    querySearchAsync(queryString, cb) {
      var that = this
      if (queryString.trim().length > 0) {
        // JSAPI 2.0 输入提示插件名称由 AMap.Autocomplete 变更为 AMap.AutoComplete 啦！
        that.AMap.plugin('AMap.AutoComplete', function () {
          var autoOptions = {
            //city 限定城市，默认全国
            city: '全国',
          }
          // 实例化AutoComplete
          var autoComplete = new that.AMap.AutoComplete(autoOptions)
          // 根据关键字进行搜索
          autoComplete.search(queryString, function (status, result) {
            // 搜索成功时，result即是对应的匹配数据
            if (status == 'complete' && result.info == 'OK') {
              var array = []
              //搜索成功时，result即是对应的匹配数据
              for (const addressObj of result.tips) {
                var obj = {}
                obj.value = addressObj.district + addressObj.name
                obj.name = addressObj.name
                obj.address = addressObj.district
                obj.lat = addressObj.location.lat
                obj.lng = addressObj.location.lng
                array.push(obj)
              }
              cb(array)
            } else {
              cb([])
            }
          })
        })
      } else {
        cb([])
      }
    },
    handleSelect(item) {
      this.editForm.locationAddress = item.name
      this.editForm.longitude = item.lng
      this.editForm.latitude = item.lat
      this.editForm.changeType = 1
    },
  },
}
</script>

<style scoped>
.mapContainer {
  width: 100%;
  height: 380px;
}

#mapContainer {
  width: 100%;
  height: 380px;
}
</style>
